import React from "react";
import type { LangCode } from "../../../_common/types.js";
import { langCodes } from "../../../_common/lang-codes.js";
import {
    CommonContent,
    NewsFeedPageContent,
    NewsPageContent,
    TutorialsListPageContent,
    GamesListPageContent,
    InfographicsFeedPageContent,
    InfographicPageContent,
    SearchPageContent,
    QuizPageContent,
    GetCommonContentData,
    GetCommonContentVariables,
    GET_COMMON_CONTENT,
} from "../../graphql/queries/common-content.js";
import { IImagePreview } from "../../graphql/queries/gallery.js";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import RootErrorMsg from "../common/errors/RootErrorMsg.js";
import Spinner from "../Spinner.js";

interface Props {
    langCode: LangCode;
    children: React.ReactNode;
}

export const Content = React.createContext<LocalizedContent>(undefined!);

interface LocalizedContent {
    languages: { [key: string]: string };
    langCode: LangCode;
    langCodes: LangCode[];
    common: CommonContent;
    newsFeedPage: NewsFeedPageContent;
    newsFeedMediaItems: (IImagePreview | null)[] | null;
    newsPage: NewsPageContent;
    tutorials: TutorialsListPageContent;
    tutorialsMediaItems: (IImagePreview | null)[] | null;
    gamesList: GamesListPageContent;
    gamesListMediaItems: (IImagePreview | null)[] | null;
    infographicsFeed: InfographicsFeedPageContent;
    infographicsFeedMediaItems: (IImagePreview | null)[] | null;
    infographicPage: InfographicPageContent;
    searchPage: SearchPageContent;
    quizPage: QuizPageContent;
}

const buildContent = (
    langCode: LangCode,
    {
        langs,
        common,
        newsFeedPage,
        newsPage,
        tutorialsListPage,
        gamesListPage,
        infographicsFeedPage,
        infographicPage,
        searchPage,
        quizPage,
    }: GetCommonContentData,
): LocalizedContent => {
    const languages = langs.contents.reduce<{ [key: string]: string }>(
        (map, c) => {
            map[c.langCode] = c.value;
            return map;
        },
        {},
    );

    return {
        languages,
        langCode: langCode,
        langCodes,
        common: common.content,
        newsPage: newsPage.content,
        newsFeedPage: newsFeedPage.content,
        newsFeedMediaItems: newsFeedPage.mediaItems,
        tutorials: tutorialsListPage.content,
        tutorialsMediaItems: tutorialsListPage.mediaItems,
        gamesList: gamesListPage.content,
        gamesListMediaItems: gamesListPage.mediaItems,
        infographicsFeed: infographicsFeedPage.content,
        infographicsFeedMediaItems: infographicsFeedPage.mediaItems,
        infographicPage: infographicPage.content,
        searchPage: searchPage.content,
        quizPage: quizPage.content,
    };
};

const CommonContentContextProvider: React.FC<Props> = ({
    langCode,
    children,
}) => {
    const { data, loading, error } = useQuery<
        GetCommonContentData,
        GetCommonContentVariables
    >(GET_COMMON_CONTENT, {
        variables: { langCode },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <RootErrorMsg />;
    }

    if (data) {
        return (
            <Content.Provider value={buildContent(langCode, data)}>
                {children}
            </Content.Provider>
        );
    }

    return <RootErrorMsg />;
};

export default CommonContentContextProvider;
