import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";
import { useParams } from "react-router";

import { Content } from "../layout/CommonContentContextProvider.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import { container } from "../styles/common.css.js";
import Spinner from "../Spinner.js";
import MetaTags from "../MetaTags.js";
import {
    GetInfographicsData,
    GetInfographicsVariables,
    GET_INFOGRAPHICS,
} from "../../graphql/queries/infographics.js";
import ShareButtons from "../common/ShareButtons.js";
import DownloadLink from "../gallery/_common/DownloadLink.js";
import Breadcrumbs from "../common/Breadcrumbs.js";
import FeaturedInfographicsList from "./FeaturedInfographicsList.js";
import HtmlBreadcrumbsStructuredData from "../helmet/HtmlBreadcrumbsStructuredData.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import MediaItem from "../gallery/_common/MediaItem.js";
import {
    imageContainer,
    pageDescription,
    pageTitle,
    articleInfo,
    appBannersContainer,
} from "./InfographicsPage.css.js";
import ArticleInfoBlock from "../common/ArticleInfoBlock.js";
import TopicTags from "../common/TopicTags.js";
import AppBanners from "../common/banners/AppBanners.js";
import { App } from "../../graphql/queries/app-config.js";
import ScrollTracker from "../common/ScrollTracker.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

const InfographicsPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const {
        langCode,
        infographicPage: { downloadInfographicBtnText },
        common: { infographics },
    } = useContext(Content);

    const { loading, error, data } = useQuery<
        GetInfographicsData,
        GetInfographicsVariables
    >(GET_INFOGRAPHICS, {
        variables: {
            id,
            langCode,
        },
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }
    if (data && data.infographics) {
        const {
            id,
            slug,
            date,
            engagementInfo,
            contentLangs,
            featuredInfographics,
            content: { title, metaTitle, preview, description, keywords },
            infographicImage: {
                content: { downloadUrl },
            },
            topics,
            promoApps,
        } = data.infographics;

        const fileName = id;

        const pagePath = `/infographics/${id}`;

        const containerClass = "flex750";
        const _promoApps = promoApps.filter((p): p is App => !!p);
        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={contentLangs}
                    path={pagePath}
                    metaTitle={metaTitle}
                    title={title}
                    description={preview}
                    mediaItem={data.infographics.previewImage}
                    keywords={keywords}
                    largeImagePreview
                />
                <HtmlBreadcrumbsStructuredData
                    pageTitle={title}
                    sectionName="infographics"
                />
                <Breadcrumbs
                    url={`/${langCode}/infographics`}
                    title={infographics}
                    location="infographics"
                    containerClass={containerClass}
                />
                <main className={container[containerClass]}>
                    <h1 className={pageTitle}>{title}</h1>
                    <ArticleInfoBlock date={date} className={articleInfo} />
                    <TopicTags
                        topics={topics}
                        place="infographics_top"
                        cpath={`/infographics/${id}`}
                    />
                    {description && (
                        <MarkdownRenderer
                            className={pageDescription}
                            source={description}
                        />
                    )}
                    <ShareButtons
                        pagePath={pagePath}
                        pageTitle={title}
                        position="top"
                        typename="Infographics"
                        medium={id}
                        engagementInfo={engagementInfo}
                        slug={slug}
                    />
                    <ScrollTracker
                        position="50%"
                        eventName="infographics_visible_50"
                    />
                    <ScrollTracker
                        position="95%"
                        eventName="infographics_visible_95"
                    />
                    <div className={imageContainer}>
                        <MediaItem
                            mediaItem={data.infographics.infographicImage}
                            srcSetSizes={{
                                tablet: 718,
                            }}
                            withCopyrights
                        />

                        <TopicTags
                            topics={topics}
                            place="infographics_bottom"
                            cpath={`/infographics/${id}`}
                        />

                        <ShareButtons
                            stickyToBottom
                            pagePath={pagePath}
                            pageTitle={title}
                            position="sticky-bottom"
                            typename="Infographics"
                            medium={id}
                            engagementInfo={engagementInfo}
                            slug={slug}
                        />
                        {downloadUrl ? (
                            <DownloadLink
                                url={downloadUrl}
                                text={downloadInfographicBtnText}
                                fileName={fileName}
                                type="infographics"
                            />
                        ) : null}
                    </div>
                    {_promoApps.length !== 0 && (
                        <div className={appBannersContainer}>
                            <AppBanners
                                promoApps={_promoApps}
                                location="infographics"
                                item={id}
                            />
                        </div>
                    )}
                </main>
                <aside className={container[containerClass]} data-nosnippet>
                    {featuredInfographics && featuredInfographics.length > 0 ? (
                        <FeaturedInfographicsList
                            featuredInfographics={featuredInfographics}
                            infographicId={id}
                        />
                    ) : null}
                </aside>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default InfographicsPage;
