import React, { useContext, useEffect, useState } from "react";
import { gaPushSubscriptionEvent } from "../../helpers/googleAnalytics.js";
import { randomSleep, sleep } from "../../helpers/utils.js";
import {
    // CookieConsentServiceContext,
    PushSubscriptionApiContext,
} from "../GlobalContextsProvider.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import {
    alertContainer,
    buttonsContainer,
    declineBtn,
    acceptBtn,
    statusIcon,
    errorContainer,
} from "./PushSubscriptionManager.css.js";
import { ConfigContext } from "../AppConfigProvider.js";
import CircleSpinner2 from "../common/CircleSpinner2.js";
import Icon from "../svg/Icon.js";
import { useLocation } from "react-router";

const PushSubscriptionManager: React.FC = () => {
    const pushSubscriptionApi = useContext(PushSubscriptionApiContext);
    // const cookieConsentService = useContext(CookieConsentServiceContext);
    const [showAlert, setShowAlert] = useState<number | false>(false);
    const { vapidPubKey } = useContext(ConfigContext);

    useEffect(() => {
        if (!pushSubscriptionApi) return;
        const checkIfShouldProposeSubscription = async () => {
            const activeSubscription =
                await pushSubscriptionApi.getPushSubscription();

            if (activeSubscription) return;

            if (pushSubscriptionApi.permission === "denied") return;

            if (await pushSubscriptionApi.shouldShowAlert()) {
                // await cookieConsentService.waitAcceptedCookie();
                const awaitedSeconds = await randomSleep(4, 15);
                setShowAlert(awaitedSeconds);
            }
        };
        checkIfShouldProposeSubscription();
    }, [pushSubscriptionApi]);

    if (!vapidPubKey) return null;

    if (showAlert)
        return (
            <PushSubscriptionAlert
                awaitedSec={showAlert}
                vapidPubKey={vapidPubKey}
                onDestroy={() => setShowAlert(false)}
            />
        );
    return null;
};

const PushSubscriptionAlert: React.FC<{
    awaitedSec: number;
    onDestroy: () => void;
    vapidPubKey: string;
}> = ({ awaitedSec, onDestroy, vapidPubKey }) => {
    const pushSubscriptionApi = useContext(PushSubscriptionApiContext);
    const { pathname } = useLocation();
    const [isShown, setIsShown] = useState<boolean>(false);
    const {
        langCode,
        common: { pSubsAlrtText },
    } = useContext(Content);

    const close = async () => {
        setIsShown(false);
        await sleep(1);
        onDestroy();
    };

    useEffect(() => {
        setIsShown(true);
        gaPushSubscriptionEvent(
            "alert",
            langCode,
            pathname,
            `after_${awaitedSec}sec`,
        );
        pushSubscriptionApi!.showedAlert();
    }, []);
    return (
        <div
            className={alertContainer}
            style={{
                transform: isShown ? "translateY(0)" : "translateY(-300%)",
                opacity: isShown ? 1 : 0,
            }}
        >
            <div>{pSubsAlrtText}</div>
            <AlertButtons
                awaitedSec={awaitedSec}
                onClose={close}
                vapidPubKey={vapidPubKey}
            />
        </div>
    );
};

type SubscriberState = "none" | "requesting" | "error" | "success";

const AlertButtons: React.FC<{
    vapidPubKey: string;
    onClose: () => {};
    awaitedSec: number;
}> = ({ onClose, vapidPubKey, awaitedSec }) => {
    const { pathname } = useLocation();
    const [state, setState] = useState<SubscriberState>("none");
    const pushSubscriptionApi = useContext(PushSubscriptionApiContext);
    const {
        langCode,
        common: { pSubsPermErr, pSubsAcptBtn, pSubsDeclBtn },
    } = useContext(Content);

    const onAccept = async () => {
        setState("requesting");
        let permission = pushSubscriptionApi!.permission;
        if (permission === "default") {
            permission = await pushSubscriptionApi!.askPermission();
        }

        if (permission === "granted") {
            const pushSubscription = await pushSubscriptionApi!.subscribe(
                vapidPubKey,
                langCode,
            );

            setState("success");
            if (pushSubscription) {
                gaPushSubscriptionEvent(
                    "success",
                    langCode,
                    pathname,
                    `after_${awaitedSec}sec`,
                );
            } else {
                gaPushSubscriptionEvent(
                    "save_error",
                    langCode,
                    pathname,
                    `after_${awaitedSec}sec`,
                );
            }
            await sleep(1);
            onClose();
        } else {
            gaPushSubscriptionEvent(
                "permission_error",
                langCode,
                pathname,
                `after_${awaitedSec}sec`,
            );
            setState("error");
        }
    };

    const onDecline = () => {
        gaPushSubscriptionEvent(
            "declined",
            langCode,
            pathname,
            `after_${awaitedSec}sec`,
        );
        onClose();
    };

    return (
        <>
            <div className={buttonsContainer}>
                <button
                    className={declineBtn}
                    onClick={onDecline}
                    disabled={state === "requesting"}
                >
                    {pSubsDeclBtn}
                </button>
                <button
                    className={acceptBtn}
                    disabled={state === "requesting"}
                    onClick={onAccept}
                >
                    <span className={statusIcon}>
                        {state == "requesting" ? (
                            <CircleSpinner2 />
                        ) : (
                            <Icon
                                k={
                                    state === "error"
                                        ? "error"
                                        : state === "success"
                                        ? "check"
                                        : "blank"
                                }
                            />
                        )}
                    </span>
                    <span>{pSubsAcptBtn}</span>
                </button>
            </div>
            {state === "error" ? (
                <div className={errorContainer}>{pSubsPermErr}</div>
            ) : null}
        </>
    );
};

export default PushSubscriptionManager;
