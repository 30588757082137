import React, { useContext, useEffect } from "react";
import { trackPageView } from "../../helpers/googleAnalytics.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import { PushSubscriptionApiContext } from "../GlobalContextsProvider.js";

interface Props {
    path: string;
    title: string;
}

const SwitchPageTracker: React.FC<Props> = ({ path, title }) => {
    const pushSubscriptionApi = useContext(PushSubscriptionApiContext);
    // TODO: pass lang code to subscription manager somewhere else, it is not safe to get it from here
    const ctx = useContext(Content);
    useEffect(() => {
        trackPageView(title, path, ctx?.langCode || "en");
        if (pushSubscriptionApi && ctx && ctx.langCode) {
            pushSubscriptionApi.triggerSession(ctx.langCode);
        }
    }, [title, path, ctx]);

    return null;
};

export default SwitchPageTracker;
