import React, { useContext } from "react";
import { ASSETS_PATH } from "../../config/content.js";
import { gaExternalLinkClick } from "../../helpers/googleAnalytics.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import { useLocation } from "react-router";

const TrustPilotLink: React.FC = () => {
    const { langCode } = useContext(Content);
    const { pathname } = useLocation();

    const to = "https://www.trustpilot.com/review/starwalk.space";
    return (
        <div style={{ textAlign: "center" }}>
            <a
                href={to}
                target="_blank"
                onClick={() => gaExternalLinkClick(to, langCode, pathname)}
                rel="noopener"
            >
                <img
                    style={{
                        width: "195px",
                        height: "47px",
                    }}
                    src={`${ASSETS_PATH}/trustpilot_badges/en/trustpilot.svg`}
                    alt="Trustpilot"
                />
            </a>
        </div>
    );
};

export default TrustPilotLink;
