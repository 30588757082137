import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { SitemapLinkData } from "../../graphql/queries/sitemap.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import { Edge } from "../../graphql/queries/_common.js";
import CircleSpinner from "../common/CircleSpinner.js";
import { itemLink, linkListContainer, listItem } from "./LinkList.css.js";

interface Props {
    links: Edge<SitemapLinkData>[];
    rootPath: string;
    loading: boolean;
}

const LinksList: React.FC<Props> = ({ links, rootPath, loading }) => {
    const { langCode } = useContext(Content);
    return (
        <ul className={linkListContainer}>
            {links.map(
                ({
                    node: {
                        id,
                        content: { title },
                    },
                }) => {
                    return (
                        <li key={id} className={listItem}>
                            <Link
                                className={itemLink}
                                to={`/${langCode}${rootPath}/${id}`}
                            >
                                {title}
                            </Link>
                        </li>
                    );
                },
            )}
            {loading && <CircleSpinner />}
        </ul>
    );
};

export default LinksList;
