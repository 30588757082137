import { useApolloClient } from "@apollo/client/react/hooks/useApolloClient.js";
import React, { useContext } from "react";
import {
    VoteQuizQuestionVariables,
    VoteValue,
    VOTE_QUIZ_QUESTION,
} from "../../../graphql/queries/games.js";
import { gaTrackQuizEvent } from "../../../helpers/googleAnalytics.js";
import Icon from "../../svg/Icon.js";
import {
    dislikeButton,
    likeButton,
    nextButton,
    nextButtonContainer,
} from "./NextButton.css.js";
import { Content } from "../../layout/CommonContentContextProvider.js";

interface Props {
    nextClick: () => void;
    buttonText: string;
    quizId: string;
    questionId: string;
}

const NextButton: React.FC<Props> = ({
    nextClick,
    buttonText,
    quizId,
    questionId,
}) => {
    const { langCode } = useContext(Content);
    const apolloClient = useApolloClient();
    const voteQuestion = async (voteValue: VoteValue) => {
        await apolloClient.mutate<any, VoteQuizQuestionVariables>({
            mutation: VOTE_QUIZ_QUESTION,
            variables: {
                input: {
                    quizId,
                    questionId,
                    voteValue,
                },
            },
        });
    };

    const likeClick = () => {
        voteQuestion("LIKE");
        gaTrackQuizEvent("NextLike", quizId, langCode, questionId);
        nextClick();
    };
    const dislikeClick = () => {
        voteQuestion("DISLIKE");
        gaTrackQuizEvent("NextDislike", quizId, langCode, questionId);
        nextClick();
    };

    return (
        <div className={nextButtonContainer}>
            <button className={dislikeButton} onClick={dislikeClick}>
                <Icon k="thumb" />
            </button>
            <button
                className={nextButton}
                onClick={() => {
                    gaTrackQuizEvent("Next", quizId, langCode, questionId);
                    nextClick();
                }}
            >
                {buttonText}
            </button>
            <button className={likeButton} onClick={likeClick}>
                <Icon k="thumb" />
            </button>
        </div>
    );
};

export default NextButton;
