import React, { useContext, useState } from "react";
import { Waypoint } from "react-waypoint";
import { gaScrollEvent } from "../../helpers/googleAnalytics.js";
import AbsolutePoint, { PointPosition } from "./AbsolutePoint.js";
import { useLocation } from "react-router";
import { Content } from "../layout/CommonContentContextProvider.js";

interface Props {
    position?: PointPosition;
    eventName: string;
    children?: React.ReactNode;
}

const ScrollTracker: React.FC<Props> = ({ position, children, eventName }) => {
    const { pathname } = useLocation();
    const { langCode } = useContext(Content);
    const [isTracked, setIsTracked] = useState(false);
    if ((!!position && !!children) || (!position && !children)) {
        throw new Error(
            "ScrollTracker: either position or children prop must be passed, not both.",
        );
    }
    const onEnter = () => {
        if (!isTracked) {
            setIsTracked(true);
            gaScrollEvent(eventName, langCode, pathname);
        }
    };
    return (
        <Waypoint onEnter={onEnter}>
            {children ? children : <AbsolutePoint position={position!} />}
        </Waypoint>
    );
};

export default ScrollTracker;
