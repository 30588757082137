import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import MetaTags from "../MetaTags.js";
import { PromoAppSetterContext } from "../layout/PromoAppContextProvider.js";
import {
    GetHomeContentData,
    GetHomeContentVariables,
    GET_HOME_CONTENT,
} from "../../graphql/queries/home.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import Spinner from "../Spinner.js";
import PartnersSection from "./sections/PartnersSection.js";
import BackSection from "./sections/BackSection.js";
import ReviewSection from "./sections/ReviewSection.js";
import ExtensiveSection from "./sections/ExtensiveSection.js";
import NewsSection from "./sections/NewsSection.js";
import ObservingSection from "./sections/ObservingSection.js";
import IntroSection from "./sections/IntroSection.js";
import QuizSection from "./sections/QuizSection.js";
import { homeMain } from "./Home.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";
import { ConfigContext } from "../AppConfigProvider.js";

const Home: React.FC = () => {
    const pickPromoApp = useContext(PromoAppSetterContext);
    const { langCode, langCodes } = useContext(Content);
    const { promoApps, appleAppMarketId } = useContext(ConfigContext);

    useEffect(() => {
        pickPromoApp({
            type: "pick",
            apps: promoApps,
        });
    }, [pickPromoApp, promoApps]);

    const { loading, error, data } = useQuery<
        GetHomeContentData,
        GetHomeContentVariables
    >(GET_HOME_CONTENT, {
        variables: { langCode, first: 6, reviewsFirst: 3 },
        notifyOnNetworkStatusChange: true, // TODO: what is it?
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (data && data.newsFeed && data.homePage) {
        const quiz = data.homePage.promoItems?.find(
            item => item.__typename === "Quiz",
        );
        const {
            homePage: {
                content: {
                    title,
                    metaDescription,
                    welcomeSecTitle,
                    welcomeSecText,
                    skyMapSecTitle,
                    skyMapSecText,
                    simpleSecTitle,
                    simpleSecText,
                    newsSecTitle,
                    newsSecText,
                    extensiveSecTitle,
                    extensiveSecText,
                    observingSecTitle,
                    observingSecText,
                    customizedSecTitle,
                    customizedSecText,
                    // arSecTitle,
                    // arSecText,
                    timeSecTitle,
                    timeSecText,
                    // toolsSecTitle,
                    // toolsSecText,
                    reviewSecTitle,
                    partnersSecTitle,
                    quizSecTitle,
                    quizSecText,
                    quizSecBtn,
                    keywords,
                },
            },
            product,
        } = data;

        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={langCodes}
                    path={""}
                    metaTitle={title}
                    title={`Star Walk 2 - ${welcomeSecTitle}`}
                    description={metaDescription}
                    promoAppId={appleAppMarketId}
                    keywords={keywords}
                />
                <main className={homeMain}>
                    <IntroSection
                        key={langCode}
                        title={welcomeSecTitle}
                        text={welcomeSecText}
                    />
                    <BackSection
                        title={skyMapSecTitle}
                        text={skyMapSecText}
                        name="skymap"
                    />
                    <ObservingSection
                        title={observingSecTitle}
                        text={observingSecText}
                    />
                    <BackSection
                        title={simpleSecTitle}
                        text={simpleSecText}
                        name="simple"
                    />
                    <NewsSection
                        title={newsSecTitle}
                        text={newsSecText}
                        news={data.newsFeed.edges.map(news => news.node)}
                    />
                    <BackSection
                        title={customizedSecTitle}
                        text={customizedSecText}
                        name="customized"
                    />
                    <ExtensiveSection
                        title={extensiveSecTitle}
                        text={extensiveSecText}
                    />
                    <BackSection
                        title={timeSecTitle}
                        text={timeSecText}
                        name="time"
                    />
                    {product && product.content.reviews && (
                        <ReviewSection
                            title={reviewSecTitle}
                            reviews={product.content.reviews}
                        />
                    )}
                    {data.partners && (
                        <PartnersSection
                            title={partnersSecTitle}
                            partners={data.partners}
                        />
                    )}
                    {quiz && (
                        <QuizSection
                            title={quizSecTitle}
                            text={quizSecText}
                            quiz={quiz}
                            button={quizSecBtn}
                        />
                    )}
                </main>
            </>
        );
    }

    if (loading) {
        return <Spinner />;
    }

    return <NotFoundErrorMsg />;
};

export default Home;
