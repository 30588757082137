import React, { useContext, useRef, useState } from "react";

import { Content } from "../layout/CommonContentContextProvider.js";
import { useHistory, useLocation } from "react-router";
import { gaSearchQuerySubmit } from "../../helpers/googleAnalytics.js";
import {
    formStyleVariants,
    searchLabelStyleVariants,
    searchInputStyleVariants,
    searchSubmitButtonStyleVariants,
} from "./SearchForm.css.js";
import Icon from "../svg/Icon.js";

interface Props {
    q?: string;
    navCb?: () => void;
}

const SearchForm: React.FC<Props> = ({ q = "", navCb }) => {
    const {
        langCode,
        searchPage: { inputPlaceholder },
    } = useContext(Content);
    const { push } = useHistory();
    const { pathname } = useLocation();

    const [searchValue, setSearchValue] = useState(q);
    const inputRef = useRef<HTMLInputElement>(null);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();
        gaSearchQuerySubmit(
            !!navCb ? "nav" : "search_page",
            searchValue,
            langCode,
            pathname,
        );
        push(`/${langCode}/search?q=${searchValue}`);
        if (navCb) {
            navCb();
            setSearchValue("");
            if (inputRef.current) {
                inputRef.current.blur();
            }
        }
    };

    const id = `${!!navCb ? "nav-" : ""}search-input`;

    const styleVariant = !!navCb ? "nav" : "reg";

    return (
        <form
            className={formStyleVariants[styleVariant]}
            onSubmit={onSubmit}
            action={`/${langCode}/search`}
        >
            <label
                className={searchLabelStyleVariants[styleVariant]}
                htmlFor={id}
            >
                <Icon k="search" />
            </label>
            <input
                className={searchInputStyleVariants[styleVariant]}
                id={id}
                ref={inputRef}
                type="text"
                name="q"
                autoComplete="off"
                placeholder={inputPlaceholder}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                required
            />
            <button
                className={searchSubmitButtonStyleVariants[styleVariant]}
                type="submit"
                disabled={!searchValue}
            >
                <Icon k="search" />
            </button>
        </form>
    );
};

export default SearchForm;
