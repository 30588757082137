import { LangCode } from "../../../_common/types.js";
import gql from "graphql-tag";
import {
    image,
    infographicsBannerInfo,
    mediaItem,
    quizBannerInfo,
    thumbnail,
} from "./fragments.js";
import { GetStaticContentVariables, StaticContent } from "./static-content.js";
import { IMediaItem } from "./gallery.js";
import { PromoItem, QA } from "./_common.js";
import { App } from "./app-config.js";

export interface MoonCalendarContent {
    datePickerTitle: string;
    _text: string;
    title: string;
    tocHeader: string;
    metaDescription: string;
    metaTitle: string;

    locPckrAltitude: string;
    locPckrConfirmBtn: string;
    locPckrCurrentLoc: string;
    locPckrLat: string;
    locPckrLon: string;
    locPckrManualLoc: string;
    locPckrSearchLbl: string;
    locPckrSearchPlcholder: string;
    locPckrSetCoords: string;
    locPckrTZ: string;
    locPckrUseDeviceLoc: string;
    locPckrUseDeviceLocErr: string;

    age: string;
    days: string;
    illumination: string;
    magnitude: string;
    size: string;
    setTime: string;
    riseTime: string;
    culmTime: string;

    descFirstQuarter: string;
    nameFirstQuarter: string;
    descFull: string;
    nameFull: string;
    descLastQuarter: string;
    nameLastQuarter: string;
    descNew: string;
    nameNew: string;
    descWaningCrescent: string;
    nameWaningCrescent: string;
    descWaningGibbous: string;
    nameWaningGibbous: string;
    descWaxingCrescent: string;
    nameWaxingCrescent: string;
    descWaxingGibbous: string;
    nameWaxingGibbous: string;
}

export interface GeoLocationBase {
    latitude: number;
    longitude: number;
    altitude: number;
}

export interface GeoLocationOption extends GeoLocationBase {
    id: string;
    name: string;
    timezone: string;
    contentLangs: LangCode[];
    content: {
        langCode: LangCode;
        name: string;
        country: string | null;
    };
}

interface MoonCalendarStaticContent<T, M = IMediaItem>
    extends StaticContent<T, M> {
    faq: QA[] | null;
    promoItems: (PromoItem | App | null)[] | null;
}

export interface GetMoonCalendarContentData<T, M = IMediaItem> {
    staticContent: MoonCalendarStaticContent<T, M> | null;
    defaultGeoLocation: GeoLocationOption | null;
}

export interface GetMoonCalendarContentVariables
    extends GetStaticContentVariables {
    geoLocationId: string;
}

export interface SearchLocationsData {
    searchGeoLocations: {
        bookmark: string | null;
        nodes: GeoLocationOption[];
    } | null;
}

export interface SearchLocationsVariables {
    key: string;
    first?: number;
    after?: string;
    langCode?: LangCode;
}

const geoLocation = gql`
    fragment geoLocation on GeoLocation {
        id
        name
        latitude
        longitude
        altitude
        timezone
        contentLangs
        content(langCode: $langCode) {
            langCode
            name
            country
        }
    }
`;

export const SEARCH_GEO_LOCATION = gql`
    query searchGeoLocations(
        $key: GeoLocationSearchKey!
        $first: Int
        $after: GeoLocationSearchBookmark
        $langCode: LangCode
    ) {
        searchGeoLocations(
            key: $key
            first: $first
            after: $after
            langCode: $langCode
        ) {
            nodes {
                ...geoLocation
            }
        }
    }
    ${geoLocation}
`;

export const GET_MOON_CALENDAR_CONTENT = gql`
    query getMoonCalendarContent(
        $id: StaticContentID!
        $geoLocationId: GeoLocationID!
        $langCode: LangCode
    ) {
        staticContent(id: $id) {
            id
            contentLangs
            content(langCode: $langCode)
            mediaItems {
                ...mediaItem
            }
            faq(langCode: $langCode) {
                q
                a
            }
            promoItems {
                id
                __typename
                ...quizBannerInfo
                ...infographicsBannerInfo
                ... on App {
                    id
                    title
                    icon {
                        sourceUrl
                        sizes
                    }
                    marketUrl
                    marketId
                    platform
                }
            }
        }
        defaultGeoLocation: geoLocation(id: $geoLocationId) {
            ...geoLocation
        }
    }
    ${geoLocation}
    ${mediaItem}
    ${thumbnail}
    ${image}
    ${quizBannerInfo}
    ${infographicsBannerInfo}
`;

export const PICK_GEO_LOCATION = gql`
    mutation pickGeoLocation($langCode: LangCode) {
        foundGeoLocation: pickGeoLocation {
            ...geoLocation
        }
    }
    ${geoLocation}
`;
