import React from "react";
import { actionText, appNameText, button } from "./SkyTonightButton.css.js";
// import { Content } from "../layout/CommonContentContextProvider.js";
// import { ExperimentsContext } from "../GlobalContextsProvider.js";

interface Prop {
    href: string;
    buttonText: string;
    appName: string;
    onClick: () => void;
}

const SkyTonightButton: React.FC<Prop> = ({
    href,
    onClick,
    buttonText,
    appName,
}) => {
    // const { langCode } = useContext(Content);
    const getAppButtonText = buttonText.replace("%{APP_NAME}", "");

    // if (langCode === "ja") {
    //     return (
    //         <ExperimentJaButton
    //             href={href}
    //             onClick={onClick}
    //             buttonText={getAppButtonText}
    //             appName={appName}
    //         />
    //     );
    // }

    return (
        <a className={button} id="get-app-button" href={href} onClick={onClick}>
            <span className={appNameText} id="get-app-button-app-name">
                {appName}
            </span>
            <span className={actionText} id="get-app-button-text">
                {getAppButtonText}
            </span>
        </a>
    );
};

// const ExperimentJaButton: React.FC<Prop> = ({
//     href,
//     onClick,
//     buttonText,
//     appName,
// }) => {
//     const gb = useContext(ExperimentsContext);
//     const variant = gb?.getFeatureValue("ja-get-app-button-text", 0) || 0;

//     const getAppButtonTextArray = [
//         {
//             appName,
//             text: buttonText,
//         },
//         {
//             appName: "Sky Tonight",
//             text: "を試す",
//         },
//         {
//             appName: "Sky Tonight",
//             text: "をダウンロードする",
//         },
//         {
//             appName: "今すぐSky Tonight",
//             text: "をダウンロードする",
//         },
//         {
//             appName: "Sky Tonight",
//             text: "を手に入れる",
//         },
//         {
//             appName: "Sky Tonight",
//             text: "を入手",
//         },
//     ];

//     return (
//         <a className={button} id="get-app-button" href={href} onClick={onClick}>
//             <span className={appNameText} id="get-app-button-app-name">
//                 {getAppButtonTextArray[variant].appName}
//             </span>
//             <span className={actionText} id="get-app-button-text">
//                 {getAppButtonTextArray[variant].text}
//             </span>
//         </a>
//     );
// };

export default SkyTonightButton;
