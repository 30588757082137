import React, { useContext } from "react";
import { appTitleStyle, banner, bannerHeader, icon } from "./AppBanners.css.js";
import StoreButtons from "../StoreButtons.js";
import { App } from "../../../graphql/queries/app-config.js";
import { Content } from "../../layout/CommonContentContextProvider.js";
import { makeIconUrl } from "../../../helpers/utils.js";

interface AppBannerContent {
    title: string;
    iconUrl?: string;
    apps: App[];
    location: string;
    item: string;
}

interface Props {
    promoApps: App[];
    location: string;
    item: string;
}

const AppBanners: React.FC<Props> = ({ promoApps, location, item }) => {
    const {
        common: { logo },
    } = useContext(Content);

    const appBanners = promoApps.reduce<AppBannerContent[]>((map, app) => {
        const index = map.findIndex(item => item.title === app.title);

        if (index !== -1) {
            map[index].apps.push(app);
        } else {
            const apps = [app];
            const appBanner: AppBannerContent = {
                title: app.title,
                iconUrl: app.icon
                    ? makeIconUrl(app.icon.sourceUrl, app.icon.sizes)
                    : undefined,
                apps,
                location,
                item,
            };
            map.push(appBanner);
        }

        return map;
    }, []);
    return (
        <>
            {appBanners.map(({ title, iconUrl, apps }) => (
                <div className={banner} data-nosnippet key={title}>
                    <div className={bannerHeader}>
                        {iconUrl && (
                            <img
                                className={icon}
                                src={iconUrl}
                                alt={`${title} ${logo}`}
                            />
                        )}
                        <p className={appTitleStyle}>{title}</p>
                    </div>

                    <StoreButtons apps={apps} location={location} item={item} />
                </div>
            ))}
        </>
    );
};

export default AppBanners;
