import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const HtmlGoogleAds: React.FC = () => {
    useEffect(() => {
        try {
            window.googletag = window.googletag || {};
            if (!googletag.cmd) {
                (googletag as any).cmd = googletag.cmd || [];
            }
            googletag.cmd.push(() => {
                try {
                    // googletag.pubads().enableSingleRequest();
                    googletag.pubads().collapseEmptyDivs();
                    googletag.enableServices();
                } catch (e) {
                    console.error("googletag error.");
                    console.error(e);
                }
            });
        } catch (e) {
            console.error("googletag init error");
            console.error(e);
        }
    }, []);
    return (
        <Helmet>
            <script
                async
                src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            />
        </Helmet>
    );
};

export default HtmlGoogleAds;
