import { AppPlatform } from "../graphql/queries/app-config.js";
import type { LangCode, SocialMedia } from "../../_common/types.js";
import { extractLangAndCPath, langsReg } from "./utils.js";

export const trackPageView = (title: string, path: string, lang: LangCode) => {
    if (typeof gtag === "function") {
        gtag("event", "page_view", {
            page_title: title,
            page_path: path,
            cpath: stripPath(path).replace(/\?.*/, "") || "/",
            lang,
        });
    }
};

export const stripPath = (path: string) => path.replace(langsReg, "");

const trackGA4Event = (
    eventName: string,
    params: {
        lang: LangCode | "";
        cpath: string;
        place?: string;
        to?: string;
        lbl?: string;
    },
) => {
    if (typeof gtag === "function") {
        params.cpath = stripPath(params.cpath) || "/";
        gtag("event", eventName, params);
    }
};

export const trackGA4ExperimentStart = (
    experimentId: string,
    variationId: number,
    gbUserId?: string,
) => {
    if (typeof gtag === "function") {
        const { lang, cpath } = extractLangAndCPath(window.location.pathname);
        gtag("event", "experiment_viewed", {
            experiment_id: experimentId,
            variation_id: variationId,
            gb_user_id: gbUserId,
            lang,
            cpath,
        });
    }
};

const trackFBEvent = (
    action: string,
    label?: string,
    category: string = "engagement",
    value?: number,
) => {
    if (typeof fbq === "function") {
        fbq("trackCustom", category, {
            action,
            label,
            value,
        } as any);
    }
};

const storeLinkActionMap = {
    ios: "app_store",
    android: "google_play",
    huawei: "huawei_store",
    get_app_button: "get_app_button",
    in_article_conversion_link: "in_article_conversion_link",
};

export const gaConversionLinkClick = (
    kind: AppPlatform | "get_app_button" | "in_article_conversion_link",
    cpath: string,
    to: string,
    lang: LangCode,
    place?: string,
) => {
    trackGA4Event(`store_click`, {
        cpath,
        to,
        lbl: storeLinkActionMap[kind],
        lang,
        place,
    });
    trackFBEvent(
        `${storeLinkActionMap[kind]}_click`,
        `${cpath}:to:${to}${place ? `:sc:${place}` : ""}`,
        "ecommerce",
        0.1,
    );
};

export const gaShareClick = (
    socialMedia: SocialMedia,
    label: string,
    place: string,
    lang: LangCode,
    cpath: string,
) => {
    trackGA4Event(`share_click`, {
        place,
        lang,
        cpath,
        to: socialMedia,
        lbl: label,
    });
    trackFBEvent(`${place}_${socialMedia}_click`, label, "sharing", 0.3);
};

export const gaTopicClick = (
    topicId: string,
    place: string,
    cpath: string,
    lang: LangCode,
) => {
    trackGA4Event(`topic_tag_click`, {
        lang,
        place,
        cpath,
        to: `/topics/${topicId}`,
    });
    trackFBEvent(`topic_tag_click:${topicId}`, `${place}_${cpath}`);
};

export const gaChangeLang = (from: LangCode, to: LangCode, cpath: string) => {
    trackGA4Event("change_language", { lang: from, lbl: to, cpath });
    trackFBEvent("change_language", `from_${from}_to_${to}`);
};

export const gaNavClick = (
    linkName: string,
    place: "menu" | "page404" | "breadcrumbs" | "footer",
    lang: LangCode,
    cpath: string,
) => {
    trackGA4Event("navigation_click", { lang, place, cpath, to: linkName });
    trackFBEvent(linkName, `${place}_click`);
};

export const gaSocialMediaLinkClick = (
    linkName: string,
    place: "menu" | "footer",
    lang: LangCode,
    cpath: string,
) => {
    trackGA4Event("social_media_link_click", {
        lang,
        place,
        cpath,
        to: linkName,
    });
    trackFBEvent(`social_link_${linkName}_click`, `${place}_click`);
};

export const gaMobileMenu = (
    event: "open" | "close",
    lang: LangCode,
    cpath: string,
) => {
    trackGA4Event(`${event}_mobile_menu`, { lang, cpath });
    trackFBEvent(`${event}_mobile_menu`, "mobile_menu");
};

export const gaRecommendedClick = (
    type: "news" | "quiz" | "infographic",
    to: string,
    lang: LangCode,
    cpath: string,
) => {
    trackGA4Event(`recommended_${type}_click`, { lang, cpath, to });
    trackFBEvent(`recommended_${type}_click`, `${cpath}_${to}`);
};

export const gaFeedItemClick = (to: string, lang: LangCode, cpath: string) => {
    trackGA4Event(`feed_item_click`, { lang, cpath, to });
};

export const gaScrollEvent = (place: string, lang: LangCode, cpath: string) => {
    trackGA4Event(`custom_scroll_event`, { place, lang, cpath });
    trackFBEvent(place);
};

export const gaMarkdownLinkClick = (
    type:
        | "internal"
        | "internal_banner"
        | "external"
        | "hash"
        | "hashed_header",
    to: string,
    lang: LangCode,
    cpath: string,
) => {
    trackGA4Event(`in_article_${type}_link_click`, {
        lang,
        cpath,
        to: type === "internal" ? stripPath(to) : to,
    });
    trackFBEvent(`in_article_${type}_link_click`, to);
};

// export const gaCookieConsent = (
//     eventName: "accept" | "learn_more",
//     lang: LangCode,
//     cpath: string,
// ) => {
//     trackGA4Event(`cookie_consent_${eventName}_click`, { lang, cpath });
//     trackFBEvent(`cookie_consent_${eventName}_click`);
// };

export const gaTrackQuizEvent = (
    eventName:
        | "Start"
        | "Next"
        | "NextDislike"
        | "NextLike"
        | "ShowStage"
        | "ShowQuestionID"
        | "AnswerStage"
        | "AnswerQuestionID"
        | "ShowResult"
        | "Restart",
    quizId: string,
    lang: LangCode,
    label?: string,
    cpath?: string,
    place?: string,
) => {
    trackGA4Event(`quiz_${eventName}`, {
        lang,
        cpath: cpath || `/quiz/${quizId}`,
        lbl: label,
        place,
    });
    trackFBEvent(eventName, label, `quiz_${quizId}`);
};

export const gaTrackCustomBannerClick = (
    id: string,
    place: string,
    lang: LangCode,
    cpath: string,
    to: string,
) => {
    trackGA4Event(`custom_banner_click`, { lang, place, cpath, to, lbl: id });
    trackFBEvent(`click_${id}`, place, "promo");
};

export const gaDownloadLinkClick = (
    fileCategory: "infographics" | "image" | "press_kit",
    fileName: string,
    lang: LangCode,
    cpath: string,
) => {
    trackGA4Event(`download_${fileCategory}`, { lang, cpath, lbl: fileName });
    trackFBEvent(`download_${fileCategory}`, "download", `${fileName}_${lang}`);
};

export const gaNewsLinkHomeClick = (newsId: string, lang: LangCode) => {
    trackGA4Event("news_click", {
        lang,
        cpath: "/",
        to: `/news/${newsId}`,
    });
    trackFBEvent(`news_link_home_click`, newsId);
};

export const gaExternalLinkClick = (
    to: string,
    lang: LangCode,
    cpath: string,
    place?: string,
) => {
    trackGA4Event(`external_link_click`, { lang, cpath, to, place });
};

export const gaSearchQuerySubmit = (
    place: "nav" | "search_page",
    query: string,
    lang: LangCode,
    cpath: string,
) => {
    trackGA4Event(`search_query_submit`, { lang, place, cpath, lbl: query });
};

export const gaSkyObjectHomeClick = (objectId: string, lang: LangCode) => {
    trackGA4Event(`sky_object_home_click`, { lang, cpath: "/", lbl: objectId });
};

export const gaLoadMoreData = (lang: LangCode, cpath: string) => {
    trackGA4Event(`load_more`, { lang, cpath });
};

export const gaPushSubscriptionEvent = (
    eventName:
        | "alert"
        | "success"
        | "declined"
        | "permission_error"
        | "save_error",
    lang: LangCode,
    cpath: string,
    lbl?: string,
) => {
    trackGA4Event(`push_subscription_${eventName}`, { lang, cpath, lbl });
};

export const gaImageVisible = (lang: LangCode, cpath: string, lbl: string) => {
    trackGA4Event(`image_variant_visible`, { lang, cpath, lbl });
};

export const gaErrorEvent = (
    eventName: string,
    lang: LangCode | "",
    cpath: string,
    lbl: string,
) => {
    trackGA4Event(eventName, { lang, cpath, lbl });
};
