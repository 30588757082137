import React, { useContext } from "react";
import StoreButtons from "../../common/StoreButtons.js";
import { ConfigContext } from "../../AppConfigProvider.js";

interface Props {
    name: string;
}

const BackSectionButtons: React.FC<Props> = ({ name }) => {
    const { promoApps } = useContext(ConfigContext);

    return (
        <StoreButtons
            apps={promoApps}
            location={`home`}
            item={name}
            place={name}
        />
    );
};

export default BackSectionButtons;
