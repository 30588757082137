import gql from "graphql-tag";
import type { LangCode } from "../../../_common/types.js";
import { IImagePreview } from "./gallery.js";
import { mediaItemPreview } from "./fragments.js";

export interface GetCommonContentVariables {
    langCode?: LangCode;
}

export interface CommonContent {
    logo: string;

    home: string;
    search: string;
    news: string;
    tutorials: string;
    games: string;
    infographics: string;
    moon: string;
    pickLang: string;
    followUs: string;

    storeBadgeIos: string;
    storeBadgeAndroid: string;
    storeBadgeHuawei: string;

    ftrTerms: string;
    ftrPrivacy: string;
    ftrContact: string;
    ftrPressKit: string;
    ftrFollowUs: string;
    ftrAbout: string;
    ftrSitemap: string;
    ftrCookiePrefs: string;
    ftrCookiePolicy: string;

    errDefTitle: string;
    errDefMsg: string;
    errNetTitle: string;
    errNetMsg: string;
    notFoundTitle: string;
    notFoundMsg: string;

    cookieText: string;
    cookieLrnMore: string;
    cookieAcptBtn: string;

    pSubsAlrtText: string;
    pSubsAcptBtn: string;
    pSubsDeclBtn: string;
    pSubsPermErr: string;

    getAppBtn: string;
    loadMoreBtnGenrl: string;

    relatedTopics: string;

    eSubsEmailInp: string;
    eSubsFNameInp: string;
    eSubsLNameInp: string;
    eSubsSubscribeBtn: string;
    eSubsOkBtn: string;
    eSubsReqMsg: string;
    eSubsEmailInvalid: string;
    eSubsHeader: string;
    eSubsErrMsg: string;
}

export interface NewsFeedPageContent {
    title: string;
    description?: string;
    metaTitle: string;
    metaDescription: string;
    promoBannerDescription: string;
    keywords?: string;
}

export interface NewsPageContent {
    textCredit: string;
    infographicsBannerButton: string;
    featuredNewsListTitle: string;
}

export interface TutorialsListPageContent {
    title: string;
    metaTitle: string;
    metaDescription: string;
    _text: string;
    keywords?: string;
}

export interface GamesListPageContent {
    title: string;
    description?: string;
    metaTitle: string;
    metaDescription: string;
    keywords?: string;
}
export interface InfographicsFeedPageContent {
    title: string;
    description?: string;
    metaTitle: string;
    metaDescription: string;
    keywords?: string;
}
export interface InfographicPageContent {
    downloadInfographicBtnText: string;
    featuredInfographicsTitle: string;
}

export interface PartnersSectionContent {
    title: string;
}

export interface StoreBadgesAlts {
    ios: string;
    android: string;
    huawei: string;
}

export interface SearchPageContent {
    metaTitle: string;
    metaDescription: string;
    noMoreMessage: string;
    noResultsMessage: string;
    title: string;
    inputPlaceholder: string;
}
export interface QuizPageContent {
    featuredQuizzesTitle: string;
}

export interface GetCommonContentData {
    langs: {
        id: string;
        contents: {
            langCode: LangCode;
            value: string;
        }[];
    };
    common: {
        id: string;
        content: CommonContent;
    };
    newsFeedPage: {
        id: string;
        content: NewsFeedPageContent;
        mediaItems: (IImagePreview | null)[] | null;
    };
    newsPage: {
        id: string;
        content: NewsPageContent;
    };
    tutorialsListPage: {
        id: string;
        content: TutorialsListPageContent;
        mediaItems: (IImagePreview | null)[] | null;
    };
    gamesListPage: {
        id: string;
        content: GamesListPageContent;
        mediaItems: (IImagePreview | null)[] | null;
    };
    infographicsFeedPage: {
        id: string;
        content: InfographicsFeedPageContent;
        mediaItems: (IImagePreview | null)[] | null;
    };
    infographicPage: {
        id: string;
        content: InfographicPageContent;
    };
    searchPage: {
        id: string;
        content: SearchPageContent;
    };
    quizPage: {
        id: string;
        content: QuizPageContent;
    };
}

export const GET_COMMON_CONTENT = gql`
    query getCommonContent($langCode: LangCode) {
        langs: staticContent(id: "sw-site-langs") {
            id
            contents
        }
        common: staticContent(id: "sw-site-common") {
            id
            content(langCode: $langCode)
        }
        newsFeedPage: staticContent(id: "sw-site-news-feed") {
            id
            content(langCode: $langCode)
            mediaItems {
                ...mediaItemPreview
            }
        }
        newsPage: staticContent(id: "sw-site-news") {
            id
            content(langCode: $langCode)
        }
        tutorialsListPage: staticContent(id: "sw-site-tutorials-list") {
            id
            content(langCode: $langCode)
            mediaItems {
                ...mediaItemPreview
            }
        }
        gamesListPage: staticContent(id: "sw-site-games-list") {
            id
            content(langCode: $langCode)
            mediaItems {
                ...mediaItemPreview
            }
        }
        infographicsFeedPage: staticContent(id: "sw-site-infographics-feed") {
            id
            content(langCode: $langCode)
            mediaItems {
                ...mediaItemPreview
            }
        }
        infographicPage: staticContent(id: "sw-site-infographics") {
            id
            content(langCode: $langCode)
        }
        searchPage: staticContent(id: "sw-site-search") {
            id
            content(langCode: $langCode)
        }
        quizPage: staticContent(id: "sw-site-quiz") {
            id
            content(langCode: $langCode)
        }
    }
    ${mediaItemPreview}
`;
