import React, { useContext, useEffect, useState } from "react";

import VariantIcon from "./VariantIcon.js";
import { QuizQuestionContent } from "../../../graphql/queries/games.js";
import { Image } from "../../../graphql/queries/gallery.js";
import MediaItem from "../../gallery/_common/MediaItem.js";
import { gaTrackQuizEvent } from "../../../helpers/googleAnalytics.js";
import { quizText } from "./common.css.js";
import MarkdownRenderer from "../../common/MarkdownRenderer.js";
import {
    answerComment,
    answerImageContainer,
    hiddenAnswerComment,
    mediaItemWrapper,
    pickedVariantButton,
    shownAnswerImageContainer,
    stageNumber,
    title,
    variantButton,
    variantButtonText,
    variantPercent,
    variants,
} from "./Question.css.js";
import NextButton from "./NextButton.js";
import { Content } from "../../layout/CommonContentContextProvider.js";

interface Props {
    question: QuizQuestionContent<Image>;
    quizId: string;
    stage: number;
    fullStage: string;
    pickedSum: number | null;
    nextButtonText: string;
    onPickHandler: (variantId: string, isCorrect: boolean) => void;
    onNextHandler: () => void;
}

const per = (a: number | null, b: number): string => {
    return `${Math.round(100 * ((a || 0) / b))}%`;
};

const Question: React.FC<Props> = ({
    question,
    stage,
    fullStage,
    quizId,
    pickedSum,
    nextButtonText,
    onPickHandler,
    onNextHandler,
}) => {
    const { langCode } = useContext(Content);
    const [answered, setAnswered] = useState<string | null>(null);

    // const coeff = question.text.length >= 100 ? 0.75 : 1;

    useEffect(() => {
        gaTrackQuizEvent("ShowQuestionID", quizId, langCode, question.id);
        gaTrackQuizEvent("ShowStage", quizId, langCode, `${stage}`);
    }, [quizId, question, stage]);

    return (
        <>
            <div className={stageNumber}>{fullStage}</div>
            <h1 className={title}>{question.text}</h1>
            <div className={mediaItemWrapper}>
                <MediaItem
                    mediaItem={question.mediaItem}
                    showPlaceholder={false}
                    srcSetSizes={{
                        tablet: 614,
                    }}
                />
                {question.answerMediaItem && (
                    <div
                        className={
                            answered === null
                                ? answerImageContainer
                                : shownAnswerImageContainer
                        }
                    >
                        <MediaItem
                            mediaItem={question.answerMediaItem}
                            showPlaceholder={false}
                            srcSetSizes={{
                                tablet: 614,
                            }}
                        />
                    </div>
                )}
                {answered !== null && (
                    <NextButton
                        nextClick={onNextHandler}
                        buttonText={nextButtonText}
                        quizId={quizId}
                        questionId={question.id}
                    />
                )}
            </div>

            <div className={variants}>
                {question.variants.map(
                    ({ id, text, isCorrect, pickedCount }) => (
                        <button
                            className={
                                answered === id
                                    ? pickedVariantButton
                                    : variantButton
                            }
                            key={`${question.id}${id}`}
                            onClick={() => {
                                onPickHandler(id, isCorrect);
                                setAnswered(id);
                            }}
                            disabled={answered !== null}
                        >
                            <VariantIcon
                                state={
                                    answered === null ||
                                    (answered !== id && !isCorrect)
                                        ? "empty"
                                        : isCorrect
                                        ? "correct"
                                        : "wrong"
                                }
                            />
                            <span className={variantButtonText}>{text}</span>
                            {pickedSum && answered ? (
                                <span className={variantPercent}>
                                    {per(pickedCount, pickedSum)}
                                </span>
                            ) : null}
                        </button>
                    ),
                )}
            </div>
            <div
                className={
                    answered === null ? hiddenAnswerComment : answerComment
                }
            >
                {question.answerComment ? (
                    <MarkdownRenderer
                        className={quizText}
                        source={question.answerComment}
                    />
                ) : null}
            </div>
        </>
    );
};

export default Question;
