import { LangCode } from "@vitotechnology/datamodels";
import React, { useContext } from "react";
import { Decorator, FormApi } from "final-form";
import { Field, Form, FormSpy } from "react-final-form";
import { Content } from "../../layout/CommonContentContextProvider.js";
import {
    DateSetterContext,
    PickedDateContext,
} from "../contexts/DateContextProvider.js";
import { MoonCalendarContentContext } from "../MoonCalendar.js";
import Icon from "../../svg/Icon.js";
import {
    button,
    fieldsContainer,
    formContainer,
    selectField,
} from "./DatePickerForm.css.js";
import {
    formHeaderWrapper,
    popupForm,
} from "../../common/form/PopupFormStyles.css.js";
import { buttonStyle } from "../PickedDate.css.js";
import { highlightedIcon } from "../GeoLocationPicker.css.js";
import { moonCalendarIcon } from "../common.css.js";

const getMonthNames = (langCode: LangCode): string[] => {
    const d = new Date();
    d.setUTCDate(1);
    const values: string[] = [];
    for (let m = 0; m < 12; m++) {
        d.setUTCMonth(m);
        values.push(
            d.toLocaleString(langCode, {
                month: "long",
                timeZone: "UTC",
            }),
        );
    }
    return values;
};

const getDaysOfMonth = (year: number, month: number) =>
    new Date(Date.UTC(year, month + 1, 0)).getUTCDate();

const years = Array(801).fill("");

interface Props {
    onClose: () => void;
}

interface FormState {
    year: number;
    month: number;
    day: number;
}

const calculator: Decorator<FormState, FormState> = (
    form: FormApi<FormState, FormState>,
) => {
    const unsubscribe = form.subscribe(
        ({ values: { day, month, year } }) => {
            form.batch(() => {
                const maxDays = getDaysOfMonth(year, month);
                if (maxDays < day) {
                    form.change("day", maxDays);
                }
            });
        },
        { values: true },
    );
    return unsubscribe;
};

const DatePickerForm: React.FC<Props> = ({ onClose }) => {
    const { langCode } = useContext(Content);
    const dispatch = useContext(DateSetterContext);
    const { todayDate, pickedDate } = useContext(PickedDateContext);

    const { datePickerTitle, locPckrConfirmBtn } = useContext(
        MoonCalendarContentContext,
    );
    const months = getMonthNames(langCode);
    const date = new Date(pickedDate);

    return (
        <Form<FormState, FormState>
            onSubmit={({ year, month, day }) => {
                dispatch({
                    type: "pick_date",
                    date: Date.UTC(year, month, day),
                });
            }}
            decorators={[calculator]}
            initialValues={{
                year: date.getUTCFullYear(),
                month: date.getUTCMonth(),
                day: date.getUTCDate(),
            }}
            subscription={{}}
            render={({ handleSubmit, form }) => (
                <div className={popupForm}>
                    <form className={formContainer}>
                        <div className={formHeaderWrapper}>
                            <ResetDateButton
                                onClick={() => {
                                    const today = new Date(todayDate);
                                    form.batch(() => {
                                        form.change("day", today.getUTCDate());
                                        form.change(
                                            "month",
                                            today.getUTCMonth(),
                                        );
                                        form.change(
                                            "year",
                                            today.getUTCFullYear(),
                                        );
                                    });
                                }}
                            />
                            <span>{datePickerTitle}</span>
                            <button
                                type="button"
                                onClick={() => {
                                    form.restart();
                                    onClose();
                                }}
                                className={buttonStyle}
                            >
                                <Icon k="close" className={moonCalendarIcon} />
                            </button>
                        </div>
                        <div className={fieldsContainer}>
                            <Field
                                name="day"
                                render={({ input }) => {
                                    return (
                                        <FormSpy
                                            subscription={{ values: true }}
                                            render={({
                                                values: { year, month },
                                            }) => (
                                                <SimpleSelect
                                                    options={Array(
                                                        getDaysOfMonth(
                                                            year,
                                                            month,
                                                        ),
                                                    ).fill("")}
                                                    offset={1}
                                                    {...input}
                                                />
                                            )}
                                        />
                                    );
                                }}
                            />
                            <Field
                                name="month"
                                render={({ input }) => {
                                    return (
                                        <SimpleSelect
                                            options={months}
                                            {...input}
                                        />
                                    );
                                }}
                            />
                            <Field
                                name="year"
                                render={({ input }) => {
                                    return (
                                        <SimpleSelect
                                            options={years}
                                            offset={1600}
                                            {...input}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <button
                            className={button}
                            type="button"
                            onClick={() => {
                                handleSubmit();
                                onClose();
                            }}
                        >
                            {locPckrConfirmBtn}
                        </button>
                    </form>
                </div>
            )}
        />
    );
};

interface SimpleSelectProps {
    options: string[];
    onChange: (v: number) => void;
    value: number;
    offset?: number;
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
    options,
    onChange,
    value,
    offset = 0,
}) => (
    <select
        className={selectField}
        value={value}
        onChange={e => onChange(+e.currentTarget.value)}
    >
        {options.map((v, i) => (
            <option key={`${v}${i}`} value={i + offset}>
                {v || i + offset}
            </option>
        ))}
    </select>
);

interface ResetDateButtonProps {
    onClick: () => void;
}
const ResetDateButton: React.FC<ResetDateButtonProps> = ({ onClick }) => {
    const { todayDate } = useContext(PickedDateContext);

    return (
        <FormSpy
            subscription={{ values: true }}
            render={({ values }) => (
                <button
                    type="button"
                    className={buttonStyle}
                    disabled={
                        todayDate ===
                        Date.UTC(values.year, values.month, values.day)
                    }
                    onClick={onClick}
                >
                    <Icon k="resetDate" className={highlightedIcon} />
                </button>
            )}
        />
    );
};
export default DatePickerForm;
