import React, { useContext } from "react";
import { useLocation } from "react-router";

import { gaTrackCustomBannerClick } from "../../../helpers/googleAnalytics.js";
import { NewsCustomBanner } from "../../../graphql/queries/news.js";
import ImageLoader from "../../gallery/_common/ImageLoader.js";
import { Content } from "../../layout/CommonContentContextProvider.js";

const CustomBanner: React.FC<NewsCustomBanner> = ({
    id,
    imgUrl,
    alt,
    aspectRatio,
    url,
    siteAdUnit,
}) => {
    const { pathname } = useLocation();
    const { langCode } = useContext(Content);
    return (
        <a
            className="customBanner"
            href={url}
            onClick={() =>
                gaTrackCustomBannerClick(
                    id,
                    siteAdUnit,
                    langCode,
                    pathname,
                    url,
                )
            }
            target="_blank"
            rel="sponsored"
        >
            <ImageLoader
                src={imgUrl}
                alt={alt}
                aspectRatio={aspectRatio}
                showPlaceholder={false}
            />
        </a>
    );
};

export default CustomBanner;
