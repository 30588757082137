import React, { useContext } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { useLocation } from "react-router";

import Card, { makeCardContentProps } from "../common/Card.js";
import CardLabel from "../common/CardLabel.js";
import LoadMoreButton from "../common/LoadMoreButton.js";
import Spinner from "../Spinner.js";
import {
    SearchPublicationsData,
    SearchPublicationsVariables,
    SEARCH_PUBLICATIONS,
} from "../../graphql/queries/search.js";
import { gaLoadMoreData } from "../../helpers/googleAnalytics.js";
import Message from "./Message.js";
import { ConfigContext } from "../AppConfigProvider.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import { FlexFeedContainer } from "../common/FeedContainers.js";

const SearchResults: React.FC = () => {
    const {
        langCode,
        searchPage: { noMoreMessage, noResultsMessage },
    } = useContext(Content);
    const { searchFeedPageItemsCount } = useContext(ConfigContext);

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const searchQuery = searchParams.get("q");
    const { loading, error, data, fetchMore } = useQuery<
        SearchPublicationsData,
        SearchPublicationsVariables
    >(SEARCH_PUBLICATIONS, {
        variables: {
            key: searchQuery,
            langCode: langCode,
            first: searchFeedPageItemsCount,
            after: undefined,
        },
        skip: !searchQuery,
        notifyOnNetworkStatusChange: true, // TODO: what is it?
    });

    if (error) {
        return <Message messageValue={noResultsMessage} />;
    }

    if (data && data.searchPublications) {
        const { bookmark, nodes } = data.searchPublications;
        const handleLoadMore = () => {
            gaLoadMoreData(langCode, "/search");
            fetchMore({
                variables: {
                    key: searchQuery,
                    langCode: langCode,
                    first: searchFeedPageItemsCount,
                    after: bookmark,
                },
            });
        };

        const hasMoreSearchResults =
            nodes.length % searchFeedPageItemsCount === 0 && !!bookmark;

        return (
            <FlexFeedContainer>
                {nodes.length === 0 ? (
                    <Message messageValue={noResultsMessage} />
                ) : (
                    nodes.map(item => (
                        <Card
                            key={item.id}
                            srcSetSizes={{
                                laptop: 200,
                                tablet: 188,
                                phone: 160,
                            }}
                            {...makeCardContentProps(item, langCode, "/search")}
                        >
                            <CardLabel typename={item.__typename} />
                        </Card>
                    ))
                )}
                {hasMoreSearchResults ? (
                    <LoadMoreButton
                        loading={loading}
                        handleLoadMore={handleLoadMore}
                    />
                ) : (
                    nodes.length > searchFeedPageItemsCount && (
                        <Message messageValue={noMoreMessage} isSmall={true} />
                    )
                )}
            </FlexFeedContainer>
        );
    }
    if (loading) {
        return <Spinner />;
    }
    return <Message messageValue={noResultsMessage} />;
};

export default SearchResults;
