import { useContext } from "react";
import { ReactBaseProps } from "react-markdown/src/ast-to-react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { langCodes } from "../../../_common/lang-codes.js";
import { ConfigContext } from "../AppConfigProvider.js";
import {
    gaMarkdownLinkClick,
    gaConversionLinkClick,
} from "../../helpers/googleAnalytics.js";
import { Content } from "../layout/CommonContentContextProvider.js";

const langRegEx = new RegExp(`^/(${langCodes.join("|")})(/|$)`);

const MarkdownLink: React.FC<
    ReactBaseProps & { children: React.ReactNode }
> = ({ href, children }) => {
    const { pathname } = useLocation();
    const { langCode } = useContext(Content);
    const _href: string = href as string;
    const { conversionDomains } = useContext(ConfigContext);

    if (_href.startsWith("#")) {
        return (
            <a
                href={_href}
                onClick={() => {
                    gaMarkdownLinkClick("hash", _href, langCode, pathname);
                }}
            >
                {children}
            </a>
        );
    }

    let path = "";
    try {
        const { hostname, pathname: _pathname } = new URL(_href);
        if (hostname !== "starwalk.space") {
            return (
                <a
                    href={_href}
                    onClick={() => {
                        if (conversionDomains.includes(hostname)) {
                            gaConversionLinkClick(
                                "in_article_conversion_link",
                                pathname,
                                _href,
                                langCode,
                            );
                        } else {
                            gaMarkdownLinkClick(
                                "external",
                                _href,
                                langCode,
                                pathname,
                            );
                        }
                    }}
                >
                    {children}
                </a>
            );
        }
        path = _pathname;
    } catch {
        path = _href;
    }

    const to = langRegEx.test(path)
        ? path.replace(
              langRegEx,
              match => `/${langCode}${match.endsWith("/") ? "/" : ""}`,
          )
        : `/${langCode}${path}`;

    // TODO: check links with search params from markdown source

    return (
        <Link
            to={to}
            onClick={() =>
                gaMarkdownLinkClick("internal", to, langCode, pathname)
            }
        >
            {children}
        </Link>
    );
};

export default MarkdownLink;
