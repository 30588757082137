import React from "react";
// import React, { useContext, useEffect, useState } from "react";

import { container } from "../../styles/common.css.js";
import ActionsTrigger from "../../layout/ActionsTrigger.js";
import { ASSETS_PATH } from "../../../config/content.js";
import ImageLoader from "../../gallery/_common/ImageLoader.js";
import StoreButtonsContainer from "./StoreButtonsContainer.js";
import {
    appTitle,
    imageContainer,
    info,
    introSection,
    sectionContent,
    sectionText,
    subTitle,
} from "./IntroSection.css.js";
// import { ExperimentsContext } from "../../GlobalContextsProvider.js";
// import { Content } from "../../layout/CommonContentContextProvider.js";

interface Props {
    title: string;
    text: string;
}
// const alternatives = [
//     { title: "Best Stargazing App", text: "Explore the sky" },
//     { title: "Virtual Star Map", text: "Stargazing made easy" },
//     { title: "Best App to Look at Stars", text: "Easy and free stargazing" },
//     { title: "Explore The Night Sky", text: "Find your way through the stars" },
//     { title: "Space-saving Sky Map", text: "The starry sky in your arms" },
//     { title: "Your Digital Sky Guide", text: "Stars at your fingertips" },
//     { title: "Stargaze While You Can", text: "Tomorrow is Never Promised" },
//     { title: "Sky’s Best-Kept Secrets", text: "One Tap Away" },
//     { title: "Best Excuse to Stay Up Late", text: "Perfect for Starry Nights" },
// ];

const IntroSection: React.FC<Props> = ({ title, text }) => {
    // const IntroSection: React.FC<Props> = base => {
    // const { langCode } = useContext(Content);
    // const variants = [base, ...alternatives];
    // const [{ title, text }, pickVariant] = useState<Props>(base);
    // const gb = useContext(ExperimentsContext);

    // useEffect(() => {
    //     if (langCode === "en") {
    //         const v = gb?.getFeatureValue("home-page-intro-texts", 0) || 0;
    //         pickVariant(variants[v]);
    //     }
    // }, [langCode]);
    return (
        <section className={introSection}>
            <div className={container.flex}>
                <div className={sectionContent}>
                    <div className={info}>
                        <h1 className={appTitle}>Star Walk 2</h1>
                        <h2 className={subTitle}>{title}</h2>
                        <ActionsTrigger>
                            <p className={sectionText}>{text}</p>
                        </ActionsTrigger>
                        <StoreButtonsContainer />
                    </div>

                    <div className={imageContainer}>
                        <ImageLoader
                            alt="Device"
                            aspectRatio="354:701"
                            src={`${ASSETS_PATH}/intro/mobile_phone.png`}
                            showPlaceholder={false}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntroSection;
