import React, { useContext } from "react";
import { useLocation } from "react-router";

import { gaConversionLinkClick } from "../../helpers/googleAnalytics.js";
import { trackMarketUrl } from "../../helpers/utils.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import {
    PromoAppContext,
    GetButtonVisibilityContext,
} from "./PromoAppContextProvider.js";
import {
    buttonActionsContainer,
    buttonBlock,
    getButton,
    visibleButtonBlock,
} from "./ButtonActions.css.js";
import { App } from "../../graphql/queries/app-config.js";
import SkyTonightButton from "./SkyTonightButton.js";

const ButtonActions: React.FC = () => {
    const app = useContext(PromoAppContext);

    return (
        <div className={buttonActionsContainer} id="button-actions-container">
            {app ? <GetAppButtonBlock app={app} /> : null}
        </div>
    );
};

interface GetAppButtonBlockProps {
    app: App;
}

const GetAppButtonBlock: React.FC<GetAppButtonBlockProps> = ({ app }) => {
    const { pathname } = useLocation();
    const [, , page] = pathname.split("/");

    const isShown = useContext(GetButtonVisibilityContext);

    const {
        langCode,
        common: { getAppBtn },
    } = useContext(Content);

    const isSkyTonightButton = !!app?.id.includes("starwalkpro");

    const onClick = () =>
        gaConversionLinkClick("get_app_button", pathname, app.id, langCode);

    const href = trackMarketUrl(
        app.marketUrl,
        app.platform,
        langCode,
        `${page || "home"}`,
        "get-app-button",
    );

    return (
        <div
            id="get-app-button-block"
            className={isShown ? visibleButtonBlock : buttonBlock}
        >
            {isSkyTonightButton ? (
                <SkyTonightButton
                    href={href}
                    onClick={onClick}
                    buttonText={getAppBtn}
                    appName={app.title}
                />
            ) : (
                <a
                    className={getButton}
                    id="get-app-button"
                    href={href}
                    onClick={onClick}
                >
                    {getAppBtn.replace("%{APP_NAME}", app.title)}
                </a>
            )}
        </div>
    );
};

export default ButtonActions;
