import { useMutation } from "@apollo/client/react/hooks/useMutation.js";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { VITO_APP_ID } from "../../../_common/config.js";
import {
    CREATE_MESSAGE,
    SendMessageInput,
    SendMessagePayload,
    ContactsPageContent,
    Department,
} from "../../graphql/queries/contacts.js";

import { AppPlatform } from "../../graphql/queries/app-config.js";
import CircleSpinner from "../common/CircleSpinner.js";
import Icon from "../svg/Icon.js";
import Captcha from "./Captcha.js";
import {
    buttonBox,
    closeFormButton,
    contentBox,
    errorMessageText,
    formTitle,
    formWrapper,
    messageText,
    popupMessageBox,
    popupWrapper,
    titleBox,
} from "./ContactsForm.css.js";
import {
    successMessageButton,
    sendButton,
} from "../common/commonFormStyles.css.js";
import { contactsButton } from "./Contacts.css.js";
import TextField from "./formComponents/TextField.js";
import SelectField from "./formComponents/SelectField.js";
import TextAreaField from "./formComponents/TextAreaField.js";
import {
    makeCaptchaValidator,
    makeEmailValidator,
    makeRequiredValidator,
} from "../common/formValidators.js";
import Condition from "./Condition.js";

interface Props {
    contactsContent: ContactsPageContent;
}
interface FormState {
    department: Department;
    name: string;
    email: string;
    platform: AppPlatform;
    version: string;
    message: string;
}

const platformSelectOptions = {
    ios: "iOS",
    android: "Android",
};
const versionSelectOptions = {
    starwalk2: "Star Walk 2",
    "starwalk2-free": "Star Walk 2 Free",
};

const ContactForm: React.FC<Props> = ({ contactsContent }) => {
    const [isFormOpened, setIsFormOpened] = useState<boolean>(false);
    const {
        title,
        emailInvalidText,
        inputReqText,
        captchaInvalidText,
        nameInputLbl,
        nameInputPlcholder,
        emailInputLbl,
        emailInputPlaceholder,
        messageInputLbl,
        errMsgText,
        submitBtn,
        popupMsgText,
        okBtn,
        captchaTitle,
        departmentSelectLbl,
        departmentSupport,
        departmentMarketing,
        departmentDevTeam,
        platformSelectLbl,
        versionSelectLbl,
        contactBtn,
    } = contactsContent;

    const departments = {
        Support: departmentSupport,
        Marketing: departmentMarketing,
        Team: departmentDevTeam,
    };
    const onClose = () => {
        setIsFormOpened(false);
    };

    const [sendMessage, { loading }] = useMutation<
        { sendMessageToSupport: SendMessagePayload },
        { input: SendMessageInput }
    >(CREATE_MESSAGE);

    const required = makeRequiredValidator(inputReqText);

    if (isFormOpened) {
        return (
            <Form<FormState>
                onSubmit={async value => {
                    const platform =
                        value.platform === "android"
                            ? "google"
                            : value.platform;

                    const dataToSave: SendMessageInput = {
                        to: value.department,
                        source: VITO_APP_ID,
                        productInfo: {
                            productId: "star-walk-2",
                            platform: value.platform,
                            appId: value.version
                                ? `${value.version}-${platform}`
                                : undefined,
                        },
                        email: value.email,
                        name: value.name,
                        message: value.message,
                    };
                    try {
                        await sendMessage({
                            variables: { input: dataToSave },
                        });
                    } catch (e) {
                        return { "FINAL_FORM/form-error": "ERROR" };
                    }
                }}
                render={({
                    handleSubmit,
                    submitSucceeded,
                    submitting,
                    hasSubmitErrors,
                    validating,
                    form,
                }) => (
                    <div className={formWrapper}>
                        <div className={titleBox}>
                            <h3 className={formTitle}>{title}</h3>

                            <button
                                className={closeFormButton}
                                onClick={onClose}
                            >
                                <Icon k="close" />
                            </button>
                        </div>
                        <form className={contentBox}>
                            <SelectField
                                name="department"
                                label={`${departmentSelectLbl} *`}
                                optionsData={departments}
                                validator={required}
                            />

                            <TextField
                                name="name"
                                label={`${nameInputLbl} *`}
                                placeholder={nameInputPlcholder}
                                validator={required}
                            />

                            <TextField
                                name="email"
                                label={`${emailInputLbl} *`}
                                placeholder={emailInputPlaceholder}
                                validator={makeEmailValidator(emailInvalidText)}
                            />

                            <Condition when="department" is="Support">
                                <>
                                    <SelectField
                                        name="platform"
                                        label={`${platformSelectLbl} *`}
                                        optionsData={platformSelectOptions}
                                        validator={required}
                                    />
                                    <SelectField
                                        name="version"
                                        label={`${versionSelectLbl} *`}
                                        optionsData={versionSelectOptions}
                                        validator={required}
                                    />
                                </>
                            </Condition>

                            <TextAreaField
                                name="message"
                                label={`${messageInputLbl} *`}
                                validator={required}
                            />

                            <Field
                                name="captcha"
                                component={Captcha}
                                buttonText={okBtn}
                                title={captchaTitle}
                                validate={makeCaptchaValidator(
                                    captchaInvalidText,
                                )}
                            />
                            <div className={buttonBox}>
                                <button
                                    className={sendButton}
                                    type="button"
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                    disabled={submitting || validating}
                                >
                                    {!loading && <span>{submitBtn}</span>}
                                    {loading && <CircleSpinner />}
                                </button>
                            </div>
                            {hasSubmitErrors ? (
                                <p
                                    className={
                                        hasSubmitErrors
                                            ? errorMessageText
                                            : messageText
                                    }
                                >
                                    {errMsgText}
                                </p>
                            ) : null}
                        </form>
                        {submitSucceeded ? (
                            <div className={popupWrapper}>
                                <div className={popupMessageBox}>
                                    <p
                                        className={
                                            hasSubmitErrors
                                                ? errorMessageText
                                                : messageText
                                        }
                                    >
                                        {popupMsgText}
                                    </p>
                                    <button
                                        className={successMessageButton}
                                        onClick={() => {
                                            onClose();
                                            form.restart();
                                        }}
                                    >
                                        {okBtn}
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}
            />
        );
    }

    return (
        <button
            className={contactsButton}
            onClick={() => {
                setIsFormOpened(true);
            }}
        >
            {contactBtn}
        </button>
    );
};

export default ContactForm;
