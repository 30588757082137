import React, { useContext } from "react";

// Components:
import { FeedContainerGrid } from "../common/FeedContainers.js";
import Card from "../common/Card.js";

// Types & Interfaces:
import { Content } from "../layout/CommonContentContextProvider.js";
import { gaRecommendedClick } from "../../helpers/googleAnalytics.js";
import { InfographicsForFeed } from "../../graphql/queries/infographics.js";
import { cardsTitle } from "./FeaturedInfographicsList.css.js";

interface Props {
    infographicId: string;
    featuredInfographics: InfographicsForFeed[];
}

const FeaturedInfographicsList: React.FC<Props> = ({
    featuredInfographics,
    infographicId,
}) => {
    const {
        langCode,
        infographicPage: { featuredInfographicsTitle },
    } = useContext(Content);
    return (
        <>
            <h2 className={cardsTitle}>{featuredInfographicsTitle}</h2>
            <FeedContainerGrid>
                {featuredInfographics.map(item => (
                    <Card
                        key={item.id}
                        title={item.content.title}
                        date={item.date}
                        mediaItem={item.previewImage}
                        srcSetSizes={{
                            tablet: 115,
                            largePhone: 200,
                        }}
                        link={`/${langCode}/infographics/${item.id}`}
                        onClickHandler={() =>
                            gaRecommendedClick(
                                "infographic",
                                `/infographics/${item.id}`,
                                langCode,
                                `/infographics/${infographicId}`,
                            )
                        }
                        description={item.content.preview}
                    />
                ))}
            </FeedContainerGrid>
        </>
    );
};

export default FeaturedInfographicsList;
