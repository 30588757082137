import React from "react";
import { useParams } from "react-router";

import HtmlLang from "../helmet/HtmlLang.js";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { LangCode } from "../../../_common/types.js";
import PromoAppContextProvider from "./PromoAppContextProvider.js";
// import CookieConsent from "../cookie-consent/CookieConsent.js";
import ButtonActions from "./ButtonActions.js";
import { mainContent, screenBottomActions } from "./Layout.css.js";
import PushSubscriptionManager from "../push-notifications/PushSubscriptionManager.js";
import CommonContentContextProvider from "./CommonContentContextProvider.js";
// import Snowfall from "../common/Snowfall.js";

interface Props {
    children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
    const { lang } = useParams<{ lang: LangCode }>();
    return (
        <CommonContentContextProvider langCode={lang || "en"}>
            <PromoAppContextProvider>
                <HtmlLang lang={lang} />
                {/* <Snowfall /> */}
                <PushSubscriptionManager />
                <Header />
                <div className={mainContent}>{children}</div>
                <Footer />
                <div className={screenBottomActions} data-nosnippet>
                    <ButtonActions />
                    {/* <CookieConsent /> */}
                </div>
            </PromoAppContextProvider>
        </CommonContentContextProvider>
    );
};

export default Layout;
