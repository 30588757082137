import React, { useContext } from "react";
import { gaSocialMediaLinkClick } from "../../helpers/googleAnalytics.js";
import Icon from "../svg/Icon.js";
import type { IconKey } from "../svg/icons-map.js";
import { socialMediaLink, socialMediaLinks } from "./SocialMediaLinks.css.js";
import { capitalize } from "../../helpers/utils.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import { useLocation } from "react-router";
import SubscribeForm from "../subscribe/SubscribeForm.js";

export const SOCIAL_MEDIA_LINKS: Partial<Record<IconKey, string>> = {
    facebook: "https://www.facebook.com/StarWalkApp",
    instagram: "https://www.instagram.com/starwalkapp/",
    twitter: "https://twitter.com/starwalk",
    medium: "https://starwalk.medium.com/",
    youtube: "https://www.youtube.com/user/VITOiPhone/featured",
    tiktok: "https://www.tiktok.com/@starwalkapp",
    // pinterest: "https://www.pinterest.ru/starwalk_app/",
};
interface Props {
    position: "menu" | "footer";
}

const SocialMediaLinks: React.FC<Props> = ({ position }) => {
    const { langCode } = useContext(Content);
    const { pathname } = useLocation();
    const socialMediaArray = Object.entries(SOCIAL_MEDIA_LINKS);
    return (
        <div className={socialMediaLinks}>
            {socialMediaArray.map(([iconKey, link]) => (
                <a
                    key={iconKey}
                    className={socialMediaLink}
                    href={link}
                    id={`link-social-${iconKey}-${position}`}
                    aria-label={capitalize(iconKey)}
                    onClick={() => {
                        gaSocialMediaLinkClick(
                            iconKey,
                            position,
                            langCode,
                            pathname,
                        );
                    }}
                >
                    <Icon k={iconKey as IconKey} />
                </a>
            ))}
            <SubscribeForm />
        </div>
    );
};

export default SocialMediaLinks;
