import React from "react";
import { Switch, Route } from "react-router";

import { langCodes } from "../../_common/lang-codes.js";
import Layout from "./layout/Layout.js";
import NewsFeed from "./newsFeed/NewsFeed.js";
import News from "./news/News.js";
import "./styles/global.css.js";
import Home from "./home/Home.js";
import StaticTextContent from "./common/StaticTextContent.js";
import TutorialsLoader from "./tutorials/list/TutorialsLoader.js";
import TutorialLoader from "./tutorials/item/TutorialLoader.js";
import QuizLoader from "./games/quiz/QuizLoader.js";
import GamesLoader from "./games/list/GamesLoader.js";
import ImagePage from "./gallery/ImagePage.js";
import InfographicsPage from "./infographics/InfographicsPage.js";
import InfographicsFeed from "./infographics/InfographicsFeed.js";
import Contacts from "./contacts/Contacts.js";
import About from "./about/About.js";
import Sitemap from "./sitemap/Sitemap.js";
import SearchPage from "./search/SearchPage.js";
import TopicPage from "./topics/TopicPage.js";
import GlobalContextsProvider from "./GlobalContextsProvider.js";
import MoonCalendar from "./moon-calendar/MoonCalendar.js";
import NotFoundErrorMsg from "./common/errors/NotFoundErrorMsg.js";
import AppConfigProvider from "./AppConfigProvider.js";
import HtmlAnalytics from "./helmet/HtmlAnalytics.js";
import HtmlAppleIcons from "./helmet/HtmlAppleIcons.js";
import HtmlFacebookPixel from "./helmet/HtmlFacebookPixel.js";
import HtmlGoogleAds from "./helmet/HtmlGoogleAds.js";
// import HtmlHotJar from "./helmet/HtmlHotJar.js";
import HtmlInit from "./helmet/HtmlInit.js";
import HtmlTermly from "./helmet/HtmlTermly.js";
import HtmlAdSense from "./helmet/HtmlAdSense.js";

const langParam = `/:lang(${langCodes.join("|")})`;

const AppRoot: React.FC = () => (
        <GlobalContextsProvider>
        <AppConfigProvider>
            <HtmlInit />
            <HtmlTermly />
            {/* <HtmlHotJar /> */}
            <HtmlAppleIcons />
            <HtmlAnalytics />
            <HtmlGoogleAds />
            <HtmlAdSense />
            <HtmlFacebookPixel />
            <Switch>
                <Route
                    sensitive={true}
                    path={langParam}
                    render={({
                        match: {
                            params: { lang },
                        },
                    }) => (
                        <Layout>
                            <Switch>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}`}
                                    exact={true}
                                    strict={true}
                                >
                                    <Home />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/news`}
                                    exact={true}
                                    strict={true}
                                >
                                    <NewsFeed path="/news" />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/news/:newsID`}
                                    exact={true}
                                    strict={true}
                                    render={({ match: { params } }) => (
                                        <News
                                            path={`/news/${params.newsID}`}
                                            newsID={params.newsID}
                                            key={params.newsID}
                                        />
                                    )}
                                />
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/tutorials`}
                                    exact={true}
                                    strict={true}
                                >
                                    <TutorialsLoader />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/tutorials/:id`}
                                    exact={true}
                                    strict={true}
                                >
                                    <TutorialLoader />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/games`}
                                    exact={true}
                                    strict={true}
                                >
                                    <GamesLoader />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/quiz/:id`}
                                    exact={true}
                                    strict={true}
                                >
                                    <QuizLoader />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/image/:id`}
                                    exact={true}
                                    strict={true}
                                    render={({ match: { params } }) => (
                                        <ImagePage
                                            path={`/image/${params.id}`}
                                        />
                                    )}
                                />
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/infographics`}
                                    exact={true}
                                    strict={true}
                                >
                                    <InfographicsFeed />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/moon-calendar`}
                                    exact={true}
                                    strict={true}
                                >
                                    <MoonCalendar />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/infographics/:id`}
                                    exact={true}
                                    strict={true}
                                >
                                    <InfographicsPage />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/about`}
                                    exact={true}
                                    strict={true}
                                >
                                    <About path="/about" />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/contacts`}
                                    exact={true}
                                    strict={true}
                                >
                                    <Contacts path="/contacts" />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/search`}
                                    exact={true}
                                    strict={true}
                                >
                                    <SearchPage />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/sitemap`}
                                    exact={true}
                                    strict={true}
                                >
                                    <Sitemap />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/topics/:id`}
                                    exact={true}
                                    strict={true}
                                >
                                    <TopicPage />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/privacy-policy`}
                                    exact={true}
                                    strict={true}
                                >
                                    <StaticTextContent
                                        id="vito-privacy-policy"
                                        path={"/privacy-policy"}
                                    />
                                </Route>
                                <Route
                                    sensitive={true}
                                    path={`/${lang}/terms-of-use`}
                                    exact={true}
                                    strict={true}
                                >
                                    <StaticTextContent
                                        id="vito-terms-of-use"
                                        path={"/terms-of-use"}
                                    />
                                </Route>
                                <Route render={() => <NotFoundErrorMsg />} />
                            </Switch>
                        </Layout>
                    )}
                />
                <Route
                    sensitive={true}
                    render={() => (
                        <Layout>
                            <NotFoundErrorMsg />
                        </Layout>
                    )}
                />
            </Switch>
        </AppConfigProvider>
        </GlobalContextsProvider>
    );

export default AppRoot;
