import React, { useContext } from "react";
import { badgesLayout, link } from "./StoreButtons.css.js";
import { App } from "../../graphql/queries/app-config.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import { ASSETS_PATH } from "../../config/content.js";
import ImageLoader from "../gallery/_common/ImageLoader.js";
import { trackMarketUrl } from "../../helpers/utils.js";
import { gaConversionLinkClick } from "../../helpers/googleAnalytics.js";
import { useLocation } from "react-router";

interface Props {
    apps: App[];
    location: string;
    item: string;
    place?: string;
    variant?: number;
}

const STORE_BADGES = {
    ios: "appstore.svg",
    android: "googleplay.svg",
    huawei: "huaweistore.svg",
};

const StoreButtons: React.FC<Props> = ({
    apps,
    location,
    item,
    place,
    variant,
}) => {
    const { pathname } = useLocation();
    const {
        langCode,
        common: { storeBadgeIos, storeBadgeAndroid, storeBadgeHuawei },
    } = useContext(Content);

    const storeBadgesAlts = {
        ios: storeBadgeIos,
        android: storeBadgeAndroid,
        huawei: storeBadgeHuawei,
    };

    return (
        <div className={badgesLayout}>
            {apps.map(({ id, marketUrl, platform }) => (
                <a
                    className={link}
                    key={marketUrl}
                    href={trackMarketUrl(
                        marketUrl,
                        platform,
                        langCode,
                        location,
                        item,
                        variant,
                    )}
                    target="_blank"
                    onClick={() =>
                        gaConversionLinkClick(
                            platform,
                            pathname,
                            id,
                            langCode,
                            place,
                        )
                    }
                >
                    <ImageLoader
                        aspectRatio="127:40"
                        showPlaceholder={false}
                        src={`${ASSETS_PATH}/badges/${langCode}/${STORE_BADGES[platform]}`}
                        alt={storeBadgesAlts[platform]}
                    />
                </a>
            ))}
        </div>
    );
};

export default StoreButtons;
