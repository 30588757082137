import React, { useContext } from "react";
import {
    App,
    AppConfig,
    ConfigData,
    GET_APP_CONFIG,
    GetAppConfigData,
} from "../graphql/queries/app-config";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import Spinner from "./Spinner.js";
import RootErrorMsg from "./common/errors/RootErrorMsg.js";
import { ExperimentsContext } from "./GlobalContextsProvider.js";

export const ConfigContext = React.createContext<ConfigContextData>(undefined!);

interface Props {
    children: React.ReactNode;
}

interface ConfigContextData extends ConfigData {
    promoApps: App[];
    appleAppMarketId?: string;
}

const buildConfig = (app: AppConfig): ConfigContextData => {
    const promoApps = app.promoApps.filter((a): a is App => !!a) || [];

    return {
        conversionDomains: [],
        newsFeedPageItemsCount: 20,
        searchFeedPageItemsCount: 15,
        recommendedNewsCount: 4,
        ...app.config,
        promoApps,
        appleAppMarketId: promoApps.find(a => a.platform === "ios")?.marketId,
    };
};

const AppConfigProvider: React.FC<Props> = ({ children }) => {
    const growthbook = useContext(ExperimentsContext);

    const { data, loading, error } = useQuery<GetAppConfigData>(GET_APP_CONFIG);

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <RootErrorMsg />;
    }

    if (data && data.app) {
        const app = data.app;
        if (app.config?.experimentsFeatures && growthbook) {
            growthbook.setFeatures(app.config?.experimentsFeatures);
        }
        return (
            <ConfigContext.Provider value={buildConfig(app)}>
                {children}
            </ConfigContext.Provider>
        );
    }

    return <RootErrorMsg />;
};

export default AppConfigProvider;
