import React, { useContext } from "react";

// Components:
import { container } from "../styles/common.css.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import { COPYRIGHT_LINK, COMPANY_NAME } from "../../../_common/content.js";
import { ASSETS_PATH } from "../../config/content.js";
import { Link, useLocation } from "react-router-dom";
import SocialMediaLinks from "../common/SocialMediaLinks.js";
import {
    gaDownloadLinkClick,
    gaExternalLinkClick,
    gaNavClick,
} from "../../helpers/googleAnalytics.js";
import {
    followUsContainer,
    followUsTitle,
    footerInner,
    footerSection,
    footerLink,
    linksContainer,
    menuLink,
    menuLinksContainer,
    serviceLinks,
    copyright,
} from "./Footer.css.js";
import { ConfigContext } from "../AppConfigProvider.js";

const Footer: React.FC = () => {
    const {
        langCode,
        common: {
            ftrTerms,
            ftrPrivacy,
            ftrContact,
            ftrPressKit,
            ftrFollowUs,
            ftrSitemap,
            ftrAbout,
            news,
            tutorials,
            games,
            infographics,
            moon,
        },
    } = useContext(Content);
    const { pathname } = useLocation();

    const menuItems = [
        {
            key: "news",
            text: news,
        },
        {
            key: "tutorials",
            text: tutorials,
        },
        {
            key: "games",
            text: games,
        },
        {
            key: "infographics",
            text: infographics,
        },
        {
            key: "moon-calendar",
            text: moon,
        },
        {
            key: "about",
            text: ftrAbout,
        },
        {
            key: "contacts",
            text: ftrContact,
        },
    ];
    return (
        <footer className={footerSection}>
            <div className={container.flex}>
                <div className={footerInner}>
                    <div className={menuLinksContainer}>
                        {menuItems.map(({ key, text }) => (
                            <Link
                                className={menuLink}
                                key={key}
                                to={`/${langCode}/${key}`}
                                onClick={() => {
                                    gaNavClick(
                                        key,
                                        "footer",
                                        langCode,
                                        pathname,
                                    );
                                }}
                            >
                                {text}
                            </Link>
                        ))}
                    </div>
                    <div className={followUsContainer}>
                        <div className={followUsTitle}>{ftrFollowUs}</div>
                        <SocialMediaLinks position="footer" />
                    </div>
                    <div className={linksContainer}>
                        <div className={copyright}>
                            <a
                                className={footerLink}
                                href={COPYRIGHT_LINK}
                                onClick={() => {
                                    gaExternalLinkClick(
                                        COPYRIGHT_LINK,
                                        langCode,
                                        pathname,
                                        "footer",
                                    );
                                }}
                            >
                                {`© ${new Date().getFullYear()} ${COMPANY_NAME}`}
                            </a>
                        </div>
                        <div className={serviceLinks}>
                            <Link
                                className={footerLink}
                                to={`/${langCode}/privacy-policy`}
                                onClick={() => {
                                    gaNavClick(
                                        "privacy_policy",
                                        "footer",
                                        langCode,
                                        pathname,
                                    );
                                }}
                            >
                                {ftrPrivacy}
                            </Link>
                            <Link
                                className={footerLink}
                                to={`/${langCode}/terms-of-use`}
                                onClick={() => {
                                    gaNavClick(
                                        "terms_of_use",
                                        "footer",
                                        langCode,
                                        pathname,
                                    );
                                }}
                            >
                                {ftrTerms}
                            </Link>
                            <a
                                className={footerLink}
                                href={`${ASSETS_PATH}/StarWalk2_PressKit.zip`}
                                download
                                onClick={() => {
                                    gaDownloadLinkClick(
                                        "press_kit",
                                        "StarWalk2_PressKit.zip",
                                        langCode,
                                        pathname,
                                    );
                                }}
                            >
                                {ftrPressKit}
                            </a>

                            <Link
                                className={footerLink}
                                to={`/${langCode}/sitemap`}
                                onClick={() => {
                                    gaNavClick(
                                        "sitemap",
                                        "footer",
                                        langCode,
                                        pathname,
                                    );
                                }}
                            >
                                {ftrSitemap}
                            </Link>

                            <CookieLinks />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

const CookieLinks: React.FC = () => {
    const { termlyId } = useContext(ConfigContext);
    const {
        common: { ftrCookiePrefs },
    } = useContext(Content);

    if (!termlyId || !ftrCookiePrefs) return null;

    return (
        <a className={`${footerLink} termly-display-preferences`} href="#">
            {ftrCookiePrefs}
        </a>
    );
};

export default Footer;
