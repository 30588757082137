import gql from "graphql-tag";

// TODO: update fragments when parametrized fragments would be supported

export const thumbnail = gql`
    fragment thumbnail on MediaFile {
        fileName
        width
        height
        length
        contentType
    }
`;

export const imageABTest = gql`
    fragment imageABTest on ImageABTest {
        experimentKey
        alternatives {
            id
            content(langCode: $langCode) {
                name
                sourceUrl
                thumbnails {
                    ...thumbnail
                }
            }
        }
    }
`;

export const imagePreview = gql`
    fragment imagePreview on Image {
        id
        content(langCode: $langCode) {
            name
            sourceUrl
            thumbnails {
                ...thumbnail
            }
        }
        abTest {
            ...imageABTest
        }
    }
    ${thumbnail}
    ${imageABTest}
`;

export const image = gql`
    fragment image on Image {
        id
        content(langCode: $langCode) {
            name
            sourceUrl
            thumbnails {
                ...thumbnail
            }
            downloadUrl
            description
            copyrights {
                author
                url
            }
        }
        abTest {
            ...imageABTest
        }
    }
    ${thumbnail}
    ${imageABTest}
`;

export const youtubeVideoPreview = gql`
    fragment youtubeVideoPreview on YoutubeVideo {
        id
        content(langCode: $langCode) {
            name
            sourceUrl
            aspectRatio
            thumbnailUrls
        }
    }
`;

export const youtubeVideo = gql`
    fragment youtubeVideo on YoutubeVideo {
        id
        content(langCode: $langCode) {
            name
            sourceUrl
            aspectRatio
            thumbnailUrls
            description
            copyrights {
                author
                url
            }
        }
    }
`;

export const mediaItemPreview = gql`
    fragment mediaItemPreview on MediaItem {
        ... on Image {
            ...imagePreview
        }
        ... on YoutubeVideo {
            ...youtubeVideoPreview
        }
    }
    ${imagePreview}
    ${youtubeVideoPreview}
`;

export const mediaItem = gql`
    fragment mediaItem on MediaItem {
        ... on Image {
            ...image
        }
        ... on YoutubeVideo {
            ...youtubeVideo
        }
    }
    ${image}
    ${youtubeVideo}
`;
export const quizBannerInfo = gql`
    fragment quizBannerInfo on Quiz {
        content(langCode: $langCode) {
            text
            metaDescription
            startButton
            mediaItem {
                ...mediaItem
            }
        }
    }
    ${mediaItem}
`;
export const infographicsBannerInfo = gql`
    fragment infographicsBannerInfo on Infographics {
        content(langCode: $langCode) {
            preview
        }
        previewImage {
            ...image
        }
    }
    ${image}
`;
