import React, { useContext } from "react";

import { container } from "../styles/common.css.js";
import FeaturedNewsList from "./FeaturedNewsList.js";
import AppBanners from "../common/banners/AppBanners.js";
import Breadcrumbs from "../common/Breadcrumbs.js";
import ShareButtons from "../common/ShareButtons.js";
import GoogleAdSenseAdUnit from "../common/ads/GoogleAdSenseAdUnit.js";
import ActionsTrigger from "../layout/ActionsTrigger.js";
import ScrollTracker from "../common/ScrollTracker.js";
import { NewsItem, NewsForFeed } from "../../graphql/queries/news.js";
import { PromoItem } from "../../graphql/queries/_common.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import ArticleInfoBlock from "../common/ArticleInfoBlock.js";
import CopyrightBlock from "../common/CopyrightBlock.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import CustomAdUnit from "../common/banners/CustomAdUnit.js";
import MediaItem from "../gallery/_common/MediaItem.js";
import { App } from "../../graphql/queries/app-config.js";
import TrustPilotLink from "../common/TrustPilotLink.js";
import {
    appBannerContainer,
    appBannersContainer,
    articleInfo,
    imageContainer,
    pageTitle,
    textStyle,
    textWrapper,
    topicsContainer,
    trustPilotLinkWrapper,
} from "./NewsPage.css.js";
import TopicTags from "../common/TopicTags.js";
import { Image } from "../../graphql/queries/gallery.js";
import GoogleAdUnit from "../common/ads/GoogleAdUnit.js";
// import FreezeFrame from "../gallery/FreezeFrame.js";

interface Props {
    news: NewsItem;
    apps: App[];
    pagePath: string;
    featuredNews: NewsForFeed[] | null;
}

const NewsPage: React.FC<Props> = ({ news, apps, pagePath, featuredNews }) => {
    const tocHeader = news.content.tocHeader;
    const {
        langCode,
        common: { news: newsMenuItemTitle },
    } = useContext(Content);
    const containerClass = "flex1000";

    const mediaItem = news.mediaItem || news.image;

    return (
        <>
            <Breadcrumbs
                url={`/${langCode}/news`}
                title={newsMenuItemTitle}
                location="news"
                containerClass={containerClass}
            />
            <main className={container[containerClass]}>
                <CustomAdUnit
                    name="news_leaderboard"
                    customBanners={news.customBanners}
                    wrapped
                />
                <article
                    className={container.grid}
                    style={{ padding: 0, rowGap: 0 }}
                >
                    <h1 className={`${pageTitle} headline`}>
                        {news.content.title}
                    </h1>
                    <div className={topicsContainer}>
                        <ArticleInfoBlock
                            date={news.date}
                            readingTime={news.content.readingTime}
                            className={articleInfo}
                        />
                        <TopicTags
                            topics={news.topics}
                            place="news_top"
                            cpath={`/news/${news.id}`}
                        />
                    </div>
                    <div className={imageContainer}>
                        <MediaItem
                            mediaItem={mediaItem}
                            srcSetSizes={{
                                laptop: 968,
                                tablet: 736,
                            }}
                            withCopyrights
                        />
                    </div>
                    <ScrollTracker
                        position="50%"
                        eventName="article_visible_50"
                    />
                    <ScrollTracker
                        position="95%"
                        eventName="article_visible_95"
                    />
                    <div className={textWrapper}>
                        <ShareButtons
                            pagePath={pagePath}
                            pageTitle={news.content.title}
                            position="top"
                            typename="News"
                            medium={news.id}
                            engagementInfo={news.engagementInfo}
                            tags={news.tags || undefined}
                            slug={news.slug}
                        />
                        <MarkdownRenderer
                            className={textStyle}
                            source={news.content.text}
                            tocHeader={tocHeader}
                            promoItems={
                                news.promoItems?.filter(
                                    (p): p is PromoItem => !!p,
                                ) || undefined
                            }
                            images={
                                news.textImages?.filter(
                                    (i): i is Image => !!i,
                                ) || undefined
                            }
                            addClassnameToFirstParagraph
                            customBanners={news.customBanners}
                            adBanner={
                                <div>
                                    <GoogleAdSenseAdUnit
                                        adSlot="6449547337"
                                        itemId={news.id}
                                        key={news.id}
                                        style={{
                                            display: "block",
                                            textAlign: "center",
                                        }}
                                        adFormat="fluid"
                                        fullLayout="in-article"
                                    />
                                </div>
                            }
                            adBannerUnderToC={
                                <GoogleAdUnit id={`div-gpt-ad-under-toc-0`} />
                            }
                        />
                        <CopyrightBlock textCopyright={news.copyright} />
                        <TopicTags
                            topics={news.topics}
                            place="news_bottom"
                            cpath={`/news/${news.id}`}
                        />
                        <ShareButtons
                            pagePath={pagePath}
                            pageTitle={news.content.title}
                            position="bottom"
                            typename="News"
                            medium={news.id}
                            engagementInfo={news.engagementInfo}
                            tags={news.tags || undefined}
                            slug={news.slug}
                        />
                    </div>
                    <ActionsTrigger position="20vh" />
                    <aside className={appBannersContainer}>
                        <div className={appBannerContainer}>
                            <AppBanners
                                promoApps={apps}
                                location="news"
                                item={news.id}
                            />
                            <CustomAdUnit
                                name="aside_banner"
                                customBanners={news.customBanners}
                            />
                            <aside className={container[containerClass]}>
                                <GoogleAdSenseAdUnit
                                    adSlot="5428094109"
                                    itemId={news.id}
                                    key={news.id}
                                    style={{
                                        display: "block",
                                        width: "100%",
                                        margin: "1rem auto",
                                    }}
                                    adFormat="auto"
                                    fullWidthResponsive="true"
                                />
                            </aside>
                        </div>
                    </aside>
                    <div className={trustPilotLinkWrapper}>
                        <TrustPilotLink />
                    </div>
                </article>
            </main>

            <aside className={container[containerClass]} data-nosnippet>
                {featuredNews && featuredNews.length > 0 ? (
                    <FeaturedNewsList
                        featuredNews={featuredNews}
                        newsId={news.id}
                    />
                ) : null}
            </aside>
        </>
    );
};

export default NewsPage;
