import React, { useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { ApolloError } from "@apollo/client/errors/index.js";

import { Content } from "../../layout/CommonContentContextProvider.js";
import ErrorPage from "./ErrorPage.js";

interface Props {
    error: ApolloError;
}
const ErrorMsg: React.FC<Props & RouteComponentProps> = ({
    staticContext,
    error,
}) => {
    if (staticContext) {
        staticContext.statusCode = 500;
    }

    return error.networkError ? <NetErrorMsg /> : <DefErrorMsg />;
};

const NetErrorMsg: React.FC = () => {
    const {
        common: { errNetTitle, errNetMsg },
    } = useContext(Content);
    return <ErrorPage title={errNetTitle} message={errNetMsg} />;
};

const DefErrorMsg: React.FC = () => {
    const {
        common: { errDefTitle, errDefMsg },
    } = useContext(Content);
    return <ErrorPage title={errDefTitle} message={errDefMsg} />;
};

export default withRouter(ErrorMsg);
