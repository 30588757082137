import React, { useState, useReducer, useContext } from "react";
import { useLocation } from "react-router-dom";

import Intro from "./Intro.js";
import Summary from "./Summary.js";
import Question from "./Question.js";
import { Image } from "../../../graphql/queries/gallery.js";
import { gaTrackQuizEvent } from "../../../helpers/googleAnalytics.js";
import { App } from "../../../graphql/queries/app-config.js";
import Spinner from "../../Spinner.js";
import FeaturedQuizList from "./FeaturedQuizList.js";
import {
    Quiz,
    QuizContent,
    QuizForFeed,
} from "../../../graphql/queries/games.js";
import QuizPageAdBanner from "./QuizPageAdBanner.js";
import { quizFrame, spinnerContainer } from "./QuizContainer.css.js";
import { Content } from "../../layout/CommonContentContextProvider.js";

interface Props {
    quiz: Quiz<QuizContent<Image>, App, QuizForFeed>;
    refreshing: boolean;
    pagePath: string;
    onPickVariant: (variantId: string) => void;
    saveResult: (quizId: string, result: number) => void;
    refresh: () => void;
    featuredGames: QuizForFeed[];
}

export type QuizStage = "start" | "result" | number;

type CountCorrectReducer = (
    prevValue: number,
    action: { type: "correct" | "reset" },
) => number;

const QuizContainer: React.FC<Props> = ({
    quiz,
    pagePath,
    onPickVariant,
    saveResult,
    refresh,
    refreshing,
    featuredGames,
}) => {
    const { langCode } = useContext(Content);
    let { hash } = useLocation();

    const [stage, setStage] = useState<QuizStage>(hash === "#0" ? 0 : "start");

    const [correctAnswersCounter, dispatch] = useReducer<
        CountCorrectReducer,
        number
    >(
        (state, action) => {
            switch (action.type) {
                case "correct":
                    return state + 1;
                case "reset":
                    return 0;
                default:
                    throw new Error("Invalid action type.");
            }
        },
        0,
        () => 0,
    );

    const scrollToTop = () => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }
    };

    const { content } = quiz;

    if (refreshing) {
        return (
            <main className={quizFrame}>
                <div className={spinnerContainer}>
                    <Spinner />
                </div>
            </main>
        );
    }

    if (stage === "start") {
        return (
            <>
                <main className={quizFrame}>
                    <Intro
                        quizId={quiz.id}
                        slug={quiz.slug}
                        title={content.title}
                        text={content.text}
                        startButtonText={content.startButton}
                        mediaItem={content.mediaItem}
                        onStartHandler={() => {
                            setStage(0);
                            scrollToTop();
                            gaTrackQuizEvent(
                                "Start",
                                quiz.id,
                                langCode,
                                `${correctAnswersCounter}_of_${content.questions.length}`,
                            );
                        }}
                        pagePath={pagePath}
                        engagementInfo={quiz.engagementInfo}
                        questionsNumber={quiz.questionsNumber}
                        avgScore={quiz.avgScore}
                        usersCount={quiz.usersCount}
                        topics={quiz.topics}
                    />
                </main>
                <QuizPageAdBanner
                    id={quiz.id}
                    stage={stage}
                    questionsCount={content.questions.length}
                />
            </>
        );
    }

    if (stage === "result") {
        const sum = [...content.summaries]
            .sort((a, b) => (a.minCorrect < b.minCorrect ? 1 : -1))
            .find(summary => summary.minCorrect <= correctAnswersCounter);
        return (
            <>
                <main className={quizFrame}>
                    <Summary
                        quizId={quiz.id}
                        slug={quiz.slug}
                        restartButtonText={content.restartButton}
                        result={`${correctAnswersCounter} / ${content.questions.length}`}
                        res={`${correctAnswersCounter}_of_${content.questions.length}`}
                        title={sum!.title}
                        text={sum!.text}
                        mediaItem={sum?.mediaItem || content.mediaItem}
                        pagePath={pagePath}
                        apps={quiz.promoApps}
                        engagementInfo={quiz.engagementInfo}
                        shareMessage={content.shareMessage
                            .replace("%CORRECT%", `${correctAnswersCounter}`)
                            .replace("%TOTAL%", `${content.questions.length}`)}
                        shareBlockTitle={content.shareYourResult || undefined}
                        onRestartHandler={() => {
                            dispatch({
                                type: "reset",
                            });
                            setStage(0);
                            refresh();
                            scrollToTop();
                            gaTrackQuizEvent(
                                "Restart",
                                quiz.id,
                                langCode,
                                `${correctAnswersCounter}_of_${content.questions.length}`,
                            );
                        }}
                        topics={quiz.topics}
                    />
                </main>
                <FeaturedQuizList
                    quizId={quiz.id}
                    featuredGames={featuredGames}
                />
                <QuizPageAdBanner
                    id={quiz.id}
                    stage={stage}
                    questionsCount={content.questions.length}
                />
            </>
        );
    }

    return (
        <>
            <main className={quizFrame}>
                <Question
                    key={stage}
                    nextButtonText={
                        stage < content.questions.length - 1
                            ? content.nextQuestionButton
                            : content.showResultButton
                    }
                    pickedSum={content.questions[stage].variants.reduce(
                        (sum, v) => sum + (v.pickedCount || 0),
                        0,
                    )}
                    question={content.questions[stage]}
                    quizId={quiz.id}
                    stage={stage + 1}
                    fullStage={`${stage + 1} / ${content.questions.length}`}
                    onPickHandler={(variantId: string, isCorrect: boolean) => {
                        onPickVariant(variantId);
                        gaTrackQuizEvent(
                            "AnswerQuestionID",
                            quiz.id,
                            langCode,
                            `${variantId}${isCorrect ? "+" : ""}`,
                        );
                        gaTrackQuizEvent(
                            "AnswerStage",
                            quiz.id,
                            langCode,
                            `${stage + 1}`,
                        );
                        if (isCorrect) {
                            dispatch({
                                type: "correct",
                            });
                        }
                    }}
                    onNextHandler={() => {
                        if (stage === content.questions.length - 1) {
                            setStage("result");
                            saveResult(quiz.id, correctAnswersCounter);
                        } else {
                            setStage(stage + 1);
                        }
                        scrollToTop();
                    }}
                />
            </main>
            <QuizPageAdBanner
                id={quiz.id}
                stage={stage}
                questionsCount={content.questions.length}
            />
        </>
    );
};

export default QuizContainer;
