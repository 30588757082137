import React, { useContext } from "react";
import { ConfigContext } from "../AppConfigProvider";
import { Helmet } from "react-helmet";

const HtmlTermly: React.FC = () => {
    const { termlyId, termlyAutoBlock } = useContext(ConfigContext);
    if (!termlyId) return null;
    const autoBlock = !!termlyAutoBlock ? "on" : "off";
    return (
        <Helmet>
            <script
                async
                type="text/javascript"
                src={`https://app.termly.io/resource-blocker/${termlyId}?autoBlock=${autoBlock}`}
            />
        </Helmet>
    );
};

export default HtmlTermly;
