import React, { useContext } from "react";
import { button, container } from "./LoadMoreButton.css.js";
import CircleSpinner from "./CircleSpinner.js";
import { Content } from "../layout/CommonContentContextProvider.js";

interface Props {
    loading: boolean;
    handleLoadMore: () => void;
}

const LoadMoreButton: React.FC<Props> = ({ loading, handleLoadMore }) => {
    const {
        common: { loadMoreBtnGenrl },
    } = useContext(Content);
    return (
        <div className={container}>
            <button
                className={button}
                aria-label={loadMoreBtnGenrl}
                disabled={loading}
                onClick={handleLoadMore}
            >
                <span>{loadMoreBtnGenrl}</span>
                {loading && <CircleSpinner />}
            </button>
        </div>
    );
};

export default LoadMoreButton;
