import React, { useContext } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";

import Spinner from "../../Spinner.js";
import ErrorMsg from "../../common/errors/ErrorMsg.js";
import TutorialsList from "./TutorialsList.js";
import { Content } from "../../layout/CommonContentContextProvider.js";
import {
    GetTutorialGroupsData,
    GetTutorialGroupsVariables,
    GET_TUTORIAL_GROUPS,
} from "../../../graphql/queries/tutorials.js";
import NotFoundErrorMsg from "../../common/errors/NotFoundErrorMsg.js";

const TutorialsLoader: React.FC = () => {
    const { langCode } = useContext(Content);
    const { loading, error, data } = useQuery<
        GetTutorialGroupsData,
        GetTutorialGroupsVariables
    >(GET_TUTORIAL_GROUPS, {
        variables: {
            langCode,
        },
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (data && data.tutorialGroups) {
        const { tutorialGroups } = data;
        return <TutorialsList tutorialGroups={tutorialGroups} />;
    }

    if (loading) {
        return <Spinner />;
    }

    return <NotFoundErrorMsg />;
};

export default TutorialsLoader;
