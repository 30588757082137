import React, { useReducer } from "react";
import { App } from "../../graphql/queries/app-config.js";
import { getDevicePlatform } from "../../helpers/utils.js";
import { useLocation } from "react-router";

interface PromoAppState {
    promoApp: App | null;
    isButtonVisible: boolean;
    search: string;
}

type PromoAppAction =
    | { type: "pick"; apps: App[] }
    | { type: "show" }
    | { type: "hide" };

type PromoAppSetter = (
    prevValue: PromoAppState,
    action: PromoAppAction,
) => PromoAppState;

type Dispatch = (action: PromoAppAction) => void;

export const PromoAppContext = React.createContext<App | null>(null);
export const PromoAppSetterContext = React.createContext<Dispatch>(() => {});
export const GetButtonVisibilityContext = React.createContext<boolean>(false);

const promoAppSetter = (
    state: PromoAppState,
    action: PromoAppAction,
): PromoAppState => {
    switch (action.type) {
        case "show":
            return {
                ...state,
                isButtonVisible: true,
            };
        case "hide":
            return {
                ...state,
                isButtonVisible: false,
            };
        case "pick": {
            const searchParams = new URLSearchParams(state.search);
            const utm_medium = searchParams.get("utm_medium");
            const platform = getDevicePlatform();
            // const sourceAppFamily = utm_medium
            //     ? utm_medium.split("-")[0]
            //     : "unknown";
            return {
                ...state,
                promoApp:
                    action.apps.find(
                        a => a.platform === platform && a.id !== utm_medium,
                        // a =>
                        //     a.platform === platform &&
                        //     !a.id.includes(sourceAppFamily),
                    ) || null,
            };
        }
        default:
            throw new Error("Invalid action type in promoAppSetter");
    }
};

interface Props {
    children: React.ReactNode;
}

const PromoAppContextProvider: React.FC<Props> = ({ children }) => {
    const { search } = useLocation();
    const [{ isButtonVisible, promoApp }, pickPromoApp] = useReducer<
        PromoAppSetter,
        PromoAppState
    >(
        promoAppSetter,
        {
            promoApp: null,
            isButtonVisible: false,
            search: search,
        },
        () => ({ promoApp: null, isButtonVisible: false, search: search }),
    );

    return (
        <PromoAppSetterContext.Provider value={pickPromoApp}>
            <PromoAppContext.Provider value={promoApp}>
                <GetButtonVisibilityContext.Provider value={isButtonVisible}>
                    {children}
                </GetButtonVisibilityContext.Provider>
            </PromoAppContext.Provider>
        </PromoAppSetterContext.Provider>
    );
};

export default PromoAppContextProvider;
