import gql from "graphql-tag";
import { IImagePreview, Image, MediaItemBase } from "./gallery.js";
import { EngagementInfo, FeedConnection } from "./_common.js";
import { App } from "./app-config.js";
import { thumbnail } from "./fragments.js";
import type { LangCode } from "../../../_common/types.js";
import { Topic } from "./topics.js";

export const quizPreview = gql`
    fragment quizPreview on Quiz {
        id
        date
        content(langCode: $langCode) {
            title
            text
            mediaItem {
                id
                ... on Image {
                    ...image
                }
            }
        }
    }
`;

export interface Quiz<C, A = undefined, Q = undefined> {
    __typename: "Quiz";
    id: string;
    slug: string | null;
    date: string;
    updatedAt: string;
    featured: boolean;
    contentLangs: LangCode[];
    content: C;
    promoApps: A[];
    showVoteQuestionButtons: boolean | null;
    engagementInfo: EngagementInfo;
    featuredGames: Q[];
    usersCount: number | null;
    questionsNumber: number;
    avgScore: number | null;
    topics: (Topic | null)[];
}

export interface QuizContent<M> {
    title: string;
    metaTitle: string;
    text: string;
    metaDescription: string;
    mediaItem: M;
    startButton: string;
    nextQuestionButton: string;
    showResultButton: string;
    restartButton: string;
    shareMessage: string;
    shareYourResult: string | null;
    questions: QuizQuestionContent<M>[];
    summaries: QuizSummaryContent<M>[];
    keywords: string | null;
}

export interface QuizQuestionContent<M> {
    id: string;
    text: string;
    answerMediaItem: M | null;
    mediaItem: M | null;
    answerComment: string | null;
    variants: QuizVariantContent[];
}

export interface QuizVariantContent {
    id: string;
    text: string;
    answerComment: string | null;
    isCorrect: boolean;
    pickedCount: number | null;
}

export interface QuizSummaryContent<M> {
    title: string;
    text: string;
    minCorrect: number;
    mediaItem: M | null;
}

export type QuizForBanner = MediaItemBase<
    "Quiz",
    Pick<
        QuizContent<Image>,
        "text" | "metaDescription" | "startButton" | "mediaItem"
    >
>;

export interface GetQuizVariables {
    langCode?: LangCode;
    id: string;
}

export interface GetQuizData {
    quiz: Quiz<QuizContent<Image>, App, QuizForFeed> | null;
}

export const GET_QUIZ = gql`
    query getQuiz($langCode: LangCode, $id: QuizID!) {
        quiz(id: $id) {
            id
            slug
            date
            updatedAt
            featured
            showVoteQuestionButtons
            contentLangs
            usersCount
            questionsNumber
            avgScore
            content(langCode: $langCode) {
                title
                metaTitle
                keywords
                text
                metaDescription
                mediaItem {
                    id
                    ... on Image {
                        content(langCode: $langCode) {
                            name
                            sourceUrl
                            copyrights {
                                author
                                url
                            }
                            thumbnails {
                                ...thumbnail
                            }
                        }
                    }
                }
                startButton
                nextQuestionButton
                showResultButton
                restartButton
                shareMessage
                shareYourResult
                questions {
                    id
                    text
                    answerComment
                    answerMediaItem {
                        id
                        ... on Image {
                            content(langCode: $langCode) {
                                name
                                sourceUrl
                                copyrights {
                                    author
                                    url
                                }
                                thumbnails {
                                    ...thumbnail
                                }
                            }
                        }
                    }
                    mediaItem {
                        id
                        ... on Image {
                            content(langCode: $langCode) {
                                name
                                sourceUrl
                                copyrights {
                                    author
                                    url
                                }
                                thumbnails {
                                    ...thumbnail
                                }
                            }
                        }
                    }
                    variants {
                        id
                        text
                        answerComment
                        isCorrect
                        pickedCount
                    }
                }
                summaries {
                    title
                    text
                    minCorrect
                    mediaItem {
                        id
                        ... on Image {
                            content(langCode: $langCode) {
                                name
                                sourceUrl
                                copyrights {
                                    author
                                    url
                                }
                                thumbnails {
                                    ...thumbnail
                                }
                            }
                        }
                    }
                }
            }
            featuredGames {
                id
                date
                featured
                usersCount
                questionsNumber
                avgScore
                content(langCode: $langCode) {
                    title
                    text
                    metaDescription
                    mediaItem {
                        id
                        ... on Image {
                            content(langCode: $langCode) {
                                name
                                sourceUrl
                                thumbnails {
                                    ...thumbnail
                                }
                            }
                        }
                    }
                }
            }
            promoApps(platforms: [ios, android, huawei]) {
                id
                title
                icon {
                    sourceUrl
                    sizes
                }
                marketUrl
                marketId
                platform
            }
            engagementInfo {
                facebook
                messenger
                twitter
                telegram
                whatsapp
                native
            }
            topics {
                id
                content(langCode: $langCode) {
                    title
                }
            }
        }
    }
    ${thumbnail}
`;

export interface PickQuizQuestionVariantVariables {
    input: {
        quizId: string;
        variantId: string;
    };
}

export const PICK_QUIZ_QUESTION_VARIANT = gql`
    mutation pickQuizQuestionVariant($input: PickQuizQuestionVariantInput!) {
        pickQuizQuestionVariant(input: $input) {
            picked
        }
    }
`;

export type VoteValue = "LIKE" | "DISLIKE";

export interface VoteQuizQuestionVariables {
    input: {
        quizId: string;
        questionId: string;
        voteValue: VoteValue;
    };
}

export const VOTE_QUIZ_QUESTION = gql`
    mutation voteQuizQuestion($input: VoteQuizQuestionInput!) {
        voteQuizQuestion(input: $input) {
            voted
        }
    }
`;
export interface SaveQuizResultVariables {
    input: {
        quizId: string;
        result: number;
    };
}
export const SAVE_QUIZ_RESULT = gql`
    mutation saveQuizResult($input: SaveQuizResultInput!) {
        saveQuizResult(input: $input) {
            saved
        }
    }
`;

type QuizContentForFeed = Pick<
    QuizContent<IImagePreview>,
    "title" | "text" | "metaDescription" | "mediaItem"
>;

export type QuizForFeed = Pick<
    Quiz<QuizContentForFeed>,
    | "__typename"
    | "id"
    | "content"
    | "date"
    | "featured"
    | "usersCount"
    | "questionsNumber"
    | "avgScore"
>;

export interface GetGamesFeedVariables {
    langCode?: LangCode;
    first?: number;
    after?: string;
}

export interface GetGamesFeedData {
    games: FeedConnection<QuizForFeed> | null;
}

export const GET_GAMES_FEED = gql`
    query getGamesFeed($langCode: LangCode, $first: Int, $after: QuizCursor) {
        games: quizzes(first: $first, after: $after) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    date
                    featured
                    usersCount
                    questionsNumber
                    avgScore
                    content(langCode: $langCode) {
                        title
                        text
                        metaDescription
                        startButton
                        mediaItem {
                            id
                            ... on Image {
                                content(langCode: $langCode) {
                                    name
                                    sourceUrl
                                    thumbnails {
                                        ...thumbnail
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${thumbnail}
`;
