import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ConfigContext } from "../AppConfigProvider.js";
import { isDev } from "../../config/env.js";

export const HtmlAnalytics: React.FC = () => {
    const { googleAnalytics4Id } = useContext(ConfigContext);
    if (!googleAnalytics4Id) return null;

    const debugMode = isDev ? `,'debug_mode':true` : "";

    const gtagSnippet = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${googleAnalytics4Id}', {
        'send_page_view': false ${debugMode}
    });`;

    return (
        <Helmet>
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics4Id}`}
            />
            <script>{gtagSnippet}</script>
        </Helmet>
    );
};

export default HtmlAnalytics;
