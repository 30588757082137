import React, { useContext } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { useApolloClient } from "@apollo/client/react/hooks/useApolloClient.js";
import { NetworkStatus } from "@apollo/client/core/networkStatus.js";
import { useParams } from "react-router";

import {
    GET_QUIZ,
    GetQuizData,
    GetQuizVariables,
    PickQuizQuestionVariantVariables,
    PICK_QUIZ_QUESTION_VARIANT,
    SaveQuizResultVariables,
    SAVE_QUIZ_RESULT,
} from "../../../graphql/queries/games.js";
import { Content } from "../../layout/CommonContentContextProvider.js";
import ErrorMsg from "../../common/errors/ErrorMsg.js";
import { container } from "../../styles/common.css.js";
import Spinner from "../../Spinner.js";
import MetaTags from "../../MetaTags.js";
import QuizContainer from "./QuizContainer.js";
import Breadcrumbs from "../../common/Breadcrumbs.js";
import HtmlBreadcrumbsStructuredData from "../../helmet/HtmlBreadcrumbsStructuredData.js";
import NotFoundErrorMsg from "../../common/errors/NotFoundErrorMsg.js";
import { ConfigContext } from "../../AppConfigProvider.js";

const QuizLoader: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const {
        langCode,
        common: { games },
    } = useContext(Content);
    const { appleAppMarketId } = useContext(ConfigContext);
    const { data, loading, error, refetch, networkStatus } = useQuery<
        GetQuizData,
        GetQuizVariables
    >(GET_QUIZ, {
        variables: {
            id,
            langCode,
        },
        notifyOnNetworkStatusChange: true,
    });

    const apolloClient = useApolloClient();

    const pickQuizQuestionVariant = async (variantId: string) => {
        await apolloClient.mutate<any, PickQuizQuestionVariantVariables>({
            mutation: PICK_QUIZ_QUESTION_VARIANT,
            variables: {
                input: {
                    quizId: id,
                    variantId,
                },
            },
        });
    };
    const saveQuizResult = async (quizId: string, result: number) => {
        await apolloClient.mutate<any, SaveQuizResultVariables>({
            mutation: SAVE_QUIZ_RESULT,
            variables: {
                input: {
                    quizId,
                    result,
                },
            },
        });
    };

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (data && data.quiz && data.quiz.id === id) {
        const { quiz } = data;
        const { featuredGames } = quiz;
        const path = `/quiz/${quiz.id}`;
        const containerClass = "flex680";

        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={quiz.contentLangs}
                    path={path}
                    metaTitle={quiz.content.metaTitle}
                    title={quiz.content.title}
                    description={quiz.content.metaDescription}
                    promoAppId={appleAppMarketId}
                    mediaItem={quiz.content.mediaItem}
                    keywords={quiz.content.keywords}
                    largeImagePreview
                />
                <HtmlBreadcrumbsStructuredData
                    pageTitle={quiz.content.title}
                    sectionName="games"
                />
                <Breadcrumbs
                    url={`/${langCode}/games`}
                    title={games}
                    location="games"
                    containerClass={containerClass}
                />
                <div className={container[containerClass]}>
                    <QuizContainer
                        key={quiz.id}
                        quiz={quiz}
                        pagePath={path}
                        onPickVariant={pickQuizQuestionVariant}
                        refresh={refetch}
                        refreshing={networkStatus === NetworkStatus.refetch}
                        featuredGames={featuredGames}
                        saveResult={saveQuizResult}
                    />
                </div>
            </>
        );
    }

    if (loading) {
        return <Spinner />;
    }

    return <NotFoundErrorMsg />;
};

export default QuizLoader;
