import { useContext, useEffect, useState } from "react";
import {
    CityGeoLocation,
    GeoLocationContext,
} from "./contexts/GeoLocationContextProvider.js";
import Icon from "../svg/Icon.js";
import {
    geoLocationPickerContainer,
    geoLocationPickerButton,
    geoLocationName,
    highlightedIcon,
    countryName,
    cityName,
} from "./GeoLocationPicker.css.js";
import GeoLocationPickerForm from "./forms/GeoLocationPickerForm.js";
// import { formatTZOffset } from "./utils.js";
// import { PickedDateContext } from "./contexts/DateContextProvider.js";
// import { TimezoneContext } from "./contexts/TimezoneContextProvider.js";
import { MoonCalendarContentContext } from "./MoonCalendar.js";
import { useApolloClient } from "@apollo/client/react/hooks/useApolloClient.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import {
    GeoLocationOption,
    PICK_GEO_LOCATION,
} from "../../graphql/queries/moon-calendar.js";
import { LangCode } from "../../../_common/types.js";
import CircleSpinner2 from "../common/CircleSpinner2.js";
import { geoLocationOptionToCityGeoLocation } from "./utils.js";
import { TimezoneContext } from "./contexts/TimezoneContextProvider.js";

interface Props {
    defaultGeoLocation: CityGeoLocation;
}

const GeoLocationPicker: React.FC<Props> = ({ defaultGeoLocation }) => {
    const client = useApolloClient();
    const { langCode } = useContext(Content);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { locPckrCurrentLoc, locPckrManualLoc } = useContext(
        MoonCalendarContentContext,
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { pickedGeoLocation, pickGeoLocation } =
        useContext(GeoLocationContext);
    const { setTimezone } = useContext(TimezoneContext);

    useEffect(() => {
        const loadLocation = async () => {
            setIsLoading(true);
            try {
                const result = await client.mutate<
                    { foundGeoLocation: GeoLocationOption | null },
                    { langCode: LangCode }
                >({
                    mutation: PICK_GEO_LOCATION,
                    variables: {
                        langCode,
                    },
                });

                if (result && result.data && result.data.foundGeoLocation) {
                    pickGeoLocation({
                        type: "set",
                        picked: geoLocationOptionToCityGeoLocation(
                            result.data.foundGeoLocation,
                        ),
                    });
                    setTimezone("Current");
                } else {
                    pickGeoLocation({
                        type: "set",
                        picked: defaultGeoLocation,
                    });
                }
            } catch (e) {
                console.log(e);
                pickGeoLocation({
                    type: "set",
                    picked: defaultGeoLocation,
                });
            } finally {
                setIsLoading(false);
            }
        };
        loadLocation();
    }, [langCode]);

    if (isLoading)
        return (
            <div className={geoLocationPickerContainer}>
                <CircleSpinner2 />
            </div>
        );

    if (!pickedGeoLocation) return null;

    const title =
        pickedGeoLocation.type === "City"
            ? pickedGeoLocation.name
            : pickedGeoLocation.type === "Current"
            ? locPckrCurrentLoc
            : locPckrManualLoc;

    const subtitle =
        pickedGeoLocation.type === "City"
            ? pickedGeoLocation.country
            : `${pickedGeoLocation.latitude.toFixed(
                  2,
              )}, ${pickedGeoLocation.longitude.toFixed(2)}`;

    return (
        <div className={geoLocationPickerContainer}>
            <button
                className={geoLocationPickerButton}
                onClick={() => {
                    setIsOpen(true);
                }}
            >
                <span className={geoLocationName}>
                    <span className={cityName}>{title}</span>
                    {subtitle && (
                        <span className={countryName}>{subtitle}</span>
                    )}
                </span>
                <Icon k="location" className={highlightedIcon} />
            </button>
            {isOpen ? (
                <GeoLocationPickerForm
                    defaultGeoLocation={defaultGeoLocation}
                    onClose={() => setIsOpen(false)}
                />
            ) : null}
        </div>
    );
};

// const CurrentTimezone: React.FC = () => {
//     const { pickedDate } = useContext(PickedDateContext);
//     const { getTZOffset } = useContext(TimezoneContext);

//     return <span>{formatTZOffset(getTZOffset(pickedDate))}</span>;
// };

export default GeoLocationPicker;
