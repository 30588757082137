import type { FieldPolicy } from "@apollo/client/core/index.js";
import type { Publication, SearchResultPayload } from "./queries/search.js";
import type { FeedConnection } from "./queries/_common.js";

const feedFieldPolicy = (
    keyArgs: string[] | false = false,
): FieldPolicy<FeedConnection<Publication>> => {
    return {
        keyArgs: keyArgs,
        merge: (existing, incoming, { args }) => {
            const existingEdges = existing?.edges || [];
            const incomingEdges = incoming?.edges || [];
            return args?.after === undefined
                ? {
                      ...incoming,
                      edges: [...incomingEdges],
                  }
                : {
                      ...existing,
                      ...incoming,
                      edges: [...existingEdges, ...incomingEdges],
                  };
        },
    };
};

const searchFieldPolicy: FieldPolicy<SearchResultPayload> = {
    keyArgs: ["key"],
    merge: (existing, incoming, { args }) => {
        const existingNodes = existing?.nodes || [];
        const incomingNodes = incoming?.nodes || [];
        return args?.after === undefined
            ? {
                  ...incoming,
                  nodes: [...incomingNodes],
              }
            : {
                  ...existing,
                  ...incoming,
                  nodes: [...existingNodes, ...incomingNodes],
              };
    },
};

export const typePolicies = {
    Query: {
        fields: {
            newsFeed: feedFieldPolicy(["first"]),
            quizzes: feedFieldPolicy(),
            infographicsFeed: feedFieldPolicy(),
            searchPublications: searchFieldPolicy,
        },
    },
    Topic: {
        fields: {
            items: feedFieldPolicy(),
        },
    },
};
