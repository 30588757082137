import React, { useEffect, useRef } from "react";

interface Props {
    id: string;
}

const GoogleAdUnit: React.FC<Props> = ({ id }) => {
    const slotRef = useRef<googletag.Slot | null>(null);
    useEffect(() => {
        try {
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(() => {
                slotRef.current = googletag.defineSlot(
                    "/21933601259/starwalk-site-ad-units/news-page-under-toc",
                    [
                        [480, 320],
                        [580, 400],
                        [468, 60],
                        [300, 250],
                        [336, 280],
                        [320, 100],
                        [320, 50],
                        [320, 480],
                        [300, 50],
                        [300, 31],
                        [300, 100],
                    ],
                    id,
                );
                slotRef.current?.addService(googletag.pubads());
                googletag.display(id);
            });
        } catch (e) {
            console.error(`AdUnit ${id} init error`);
            console.error(e);
        }
        return () => {
            if (
                window.googletag &&
                !!window.googletag.destroySlots &&
                slotRef.current
            ) {
                googletag.destroySlots([slotRef.current]);
            }
        };
    }, [id]);
    return <div id={id} style={{ width: "100%" }} />;
};

export default GoogleAdUnit;
