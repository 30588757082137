import React, { useContext } from "react";

// Types & Interfaces:
import { Content } from "../layout/CommonContentContextProvider.js";
import { gaRecommendedClick } from "../../helpers/googleAnalytics.js";
import ScrollTracker from "../common/ScrollTracker.js";
import { NewsForFeed } from "../../graphql/queries/news.js";
import { FeedContainerGrid } from "../common/FeedContainers.js";
import Card from "../common/Card.js";
import { container, featuredTitle } from "./FeaturedNewsList.css.js";

interface Props {
    newsId: string;
    featuredNews: NewsForFeed[];
}

const FeaturedNewsList: React.FC<Props> = ({ featuredNews, newsId }) => {
    const {
        langCode,
        newsPage: { featuredNewsListTitle },
    } = useContext(Content);
    return (
        <div className={container}>
            <ScrollTracker
                position="50%"
                eventName="recommended_news_visible_50"
            />
            <ScrollTracker
                position="95%"
                eventName="recommended_news_visible_95"
            />
            <h2 className={featuredTitle}>{featuredNewsListTitle}</h2>
            <FeedContainerGrid>
                {featuredNews.map(
                    ({
                        id,
                        content: { title, readingTime, preview },
                        image,
                        date,
                    }) => (
                        <Card
                            key={id}
                            title={title}
                            date={date}
                            mediaItem={image}
                            srcSetSizes={{
                                desktop: 155,
                                tablet: 117,
                                largePhone: 200,
                            }}
                            readingTime={readingTime}
                            description={preview}
                            link={`/${langCode}/news/${id}`}
                            onClickHandler={() =>
                                gaRecommendedClick(
                                    "news",
                                    `/news/${id}`,
                                    langCode,
                                    `/news/${newsId}`,
                                )
                            }
                            levelHeading="h3"
                        />
                    ),
                )}
            </FeedContainerGrid>
        </div>
    );
};
// Styles for Styled Components:

export default FeaturedNewsList;
