import gql from "graphql-tag";
import { Copyright, EngagementInfo } from "./_common.js";
import { thumbnail } from "./fragments.js";
import type { Thumbnail, LangCode } from "../../../_common/types.js";

export interface MediaItemBase<T, C> {
    id: string;
    __typename: T;
    contentLangs: LangCode[];
    content: C;
}

interface MediaItemContentBase {
    name: string | null;
    description: string | null;
    copyrights: Copyright[];
    sourceUrl: string;
}

export interface ImageContent extends MediaItemContentBase {
    thumbnails: Thumbnail[];
    downloadUrl: string | null;
}

export interface YoutubeVideoContent extends MediaItemContentBase {
    thumbnailUrls: string[];
    aspectRatio: string;
    youtubeVideoId: string;
}

interface ImageABTest {
    experimentKey: string;
    alternatives: IImagePreview[];
}

export type Image = MediaItemBase<"Image", ImageContent> & {
    engagementInfo: EngagementInfo;
    abTest: ImageABTest | null;
};

export type YoutubeVideo = MediaItemBase<"YoutubeVideo", YoutubeVideoContent>;

export type IMediaItem = Image | YoutubeVideo;

// TODO: "copyrights" must be removed
export type IImagePreview = MediaItemBase<
    "Image",
    Pick<ImageContent, "name" | "sourceUrl" | "thumbnails" | "copyrights">
> & {
    abTest: ImageABTest | null;
};

// TODO: "copyrights" must be removed
export type IYoutubeVideoPreview = MediaItemBase<
    "YoutubeVideo",
    Pick<
        YoutubeVideoContent,
        "name" | "sourceUrl" | "thumbnailUrls" | "aspectRatio" | "copyrights"
    >
>;

export type IMediaItemPreview = IImagePreview | IYoutubeVideoPreview;

export interface GetImageVariables {
    langCode?: LangCode;
    id: string;
}

export interface GetImage {
    image: Image | null;
}

export const GET_IMAGE = gql`
    query getImage($langCode: LangCode, $id: ImageID!) {
        image(id: $id) {
            id
            contentLangs
            content(langCode: $langCode) {
                name
                description
                copyrights {
                    author
                    url
                }
                downloadUrl
                sourceUrl
                thumbnails {
                    ...thumbnail
                }
            }
        }
    }
    ${thumbnail}
`;
