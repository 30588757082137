import React, { useContext, useEffect, useState } from "react";
import { IImagePreview } from "../../../graphql/queries/gallery.js";
import { ExperimentsContext } from "../../GlobalContextsProvider.js";
import AspectRatio from "./AspectRatio.js";
import { SrcSetSizes } from "./ImageItem.js";
import ImageItemRenderer from "./ImageItemRenderer.js";
import { Waypoint } from "react-waypoint";
import { Content } from "../../layout/CommonContentContextProvider.js";
import { useLocation } from "react-router";
import { gaImageVisible } from "../../../helpers/googleAnalytics.js";

interface Props {
    variants: IImagePreview[];
    featureKey: string;
    srcSetSizes: SrcSetSizes;
    showPlaceholder?: boolean;
    noAspectWrapper?: boolean;
    children?: React.ReactNode;
}
const ImageItemTest: React.FC<Props> = ({
    variants,
    featureKey,
    srcSetSizes,
    showPlaceholder,
    noAspectWrapper,
    children,
}) => {
    const { langCode } = useContext(Content);
    const [pickedImage, pickImage] = useState<IImagePreview | null>(null);
    const gb = useContext(ExperimentsContext);

    useEffect(() => {
        const v = gb?.getFeatureValue(featureKey, 0) || 0;
        pickImage(variants[v]);
    }, [langCode]);

    if (pickedImage) {
        return (
            <VisibilityTracker featureKey={featureKey}>
                <ImageItemRenderer
                    image={pickedImage}
                    srcSetSizes={srcSetSizes}
                    showPlaceholder={showPlaceholder}
                    noAspectWrapper={noAspectWrapper}
                >
                    {children}
                </ImageItemRenderer>
            </VisibilityTracker>
        );
    }
    const { thumbnails } = variants[0].content;

    if (!thumbnails[0]) return null;

    const { width, height } = thumbnails[0];

    return <AspectRatio v={`${width}:${height}`} />;
};

const VisibilityTracker: React.FC<{
    featureKey: string;
    children: React.ReactNode;
}> = ({ children, featureKey }) => {
    const { langCode } = useContext(Content);
    const { pathname } = useLocation();
    const [isTracked, setIsTracked] = useState(false);

    const onEnter = () => {
        if (!isTracked) {
            setIsTracked(true);
            gaImageVisible(langCode, pathname, featureKey);
        }
    };
    return <Waypoint onEnter={onEnter}>{children}</Waypoint>;
};

export default ImageItemTest;
