import React, { useContext } from "react";
import { gaDownloadLinkClick } from "../../../helpers/googleAnalytics.js";
import { Content } from "../../layout/CommonContentContextProvider.js";
import Icon from "../../svg/Icon.js";
import { downloadLink, linkText } from "./DownloadLink.css.js";
import { useLocation } from "react-router";

interface Props {
    url: string;
    fileName: string;
    text: string;
    type: "infographics" | "image";
}

const DownloadLink: React.FC<Props> = ({ url, fileName, text, type }) => {
    const { langCode } = useContext(Content);
    const { pathname } = useLocation();
    return (
        <a
            className={downloadLink}
            href={url}
            download={fileName}
            onClick={() => {
                gaDownloadLinkClick(type, fileName, langCode, pathname);
            }}
        >
            <Icon k="download" />
            <span className={linkText}>{text}</span>
        </a>
    );
};

export default DownloadLink;
