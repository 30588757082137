import React from "react";
import { NewsCustomBanner } from "../../../graphql/queries/news.js";
import CustomBanner from "./CustomBanner.js";

interface Props {
    name: string;
    customBanners?: NewsCustomBanner[] | null;
    wrapped?: boolean;
}

const CustomAdUnit: React.FC<Props> = ({ name, customBanners, wrapped }) => {
    if (!customBanners) return null;

    const pickedBanner = customBanners.find(b => b.siteAdUnit === name);

    if (!pickedBanner) return null;

    const banner = <CustomBanner {...pickedBanner} />;

    if (wrapped)
        return (
            <aside
                style={{
                    display: "block",
                    width: "100%",
                    maxWidth: 728,
                    margin: "0 auto 1rem",
                }}
            >
                {banner}
            </aside>
        );

    return banner;
};

export default CustomAdUnit;
