import React, { useContext } from "react";

import { YoutubeVideo } from "../../../graphql/queries/gallery.js";
import { container } from "../../styles/common.css.js";
import { Content } from "../../layout/CommonContentContextProvider.js";
import MetaTags from "../../MetaTags.js";
import ShareButtons from "../../common/ShareButtons.js";
import MediaItem from "../../gallery/_common/MediaItem.js";
import ScrollTracker from "../../common/ScrollTracker.js";
import ArticleInfoBlock from "../../common/ArticleInfoBlock.js";
import CopyrightBlock from "../../common/CopyrightBlock.js";
import MarkdownRenderer from "../../common/MarkdownRenderer.js";
import Breadcrumbs from "../../common/Breadcrumbs.js";
import HtmlBreadcrumbsStructuredData from "../../helmet/HtmlBreadcrumbsStructuredData.js";
import HtmlVideoStructuredData from "../../helmet/HtmlVideoStructuredData.js";
import {
    Tutorial,
    TutorialContent,
} from "../../../graphql/queries/tutorials.js";
import {
    pageTitle,
    textContent,
    videoFrameContainer,
} from "./TutorialPage.css.js";
import TopicTags from "../../common/TopicTags.js";

interface Props {
    tutorial: Tutorial<TutorialContent, YoutubeVideo>;
}

const TutorialPage: React.FC<Props> = ({ tutorial }) => {
    const {
        langCode,
        common: { tutorials },
    } = useContext(Content);

    const path = `/tutorials/${tutorial.id}`;
    const containerClass = "flex750";

    return (
        <>
            <MetaTags
                currentLang={langCode}
                langs={tutorial.contentLangs}
                path={path}
                title={tutorial.content.metaTitle}
                description={tutorial.content.metaDescription}
                mediaItem={tutorial.mediaItem}
                keywords={tutorial.content.keywords}
            />
            <HtmlBreadcrumbsStructuredData
                pageTitle={tutorial.content.title}
                sectionName="tutorials"
            />
            {tutorial.mediaItem && (
                <HtmlVideoStructuredData
                    name={tutorial.content.title}
                    description={tutorial.content.metaDescription}
                    thumbnailUrl={tutorial.mediaItem.content.thumbnailUrls}
                    // TODO: tutorial.date is not correct uploadDate actually, we need to replace it with smthng real
                    uploadDate={tutorial.date}
                    contentUrl={tutorial.mediaItem.content.sourceUrl}
                />
            )}
            <Breadcrumbs
                url={`/${langCode}/tutorials`}
                title={tutorials}
                location="tutorials"
                containerClass={containerClass}
            />
            <main className={container[containerClass]}>
                <article>
                    <h1 className={pageTitle}>{tutorial.content.title}</h1>
                    <ArticleInfoBlock
                        date={tutorial.date}
                        readingTime={tutorial.content.readingTime}
                    />
                    <TopicTags
                        topics={tutorial.topics}
                        place="tutorial"
                        cpath={`/tutorials/${tutorial.id}`}
                    />
                    <div className={videoFrameContainer}>
                        <MediaItem
                            mediaItem={tutorial.mediaItem}
                            srcSetSizes={{}}
                            withCopyrights
                        />
                    </div>

                    <ScrollTracker
                        position="95%"
                        eventName="tutorial_visible_95"
                    />

                    <ShareButtons
                        pagePath={path}
                        pageTitle={tutorial.content.title}
                        position="top"
                        typename="Tutorial"
                        medium={tutorial.id}
                        engagementInfo={tutorial.engagementInfo}
                        slug={tutorial.slug}
                    />

                    <MarkdownRenderer
                        source={tutorial.content.text}
                        className={textContent}
                    />
                    <CopyrightBlock textCopyright={tutorial.copyright} />
                </article>
            </main>
        </>
    );
};

export default TutorialPage;
