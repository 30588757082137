import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";
import { useParams } from "react-router";
import {
    GetTopicContent,
    GetTopicVariables,
    GET_TOPIC,
} from "../../graphql/queries/topics.js";
import { gaLoadMoreData } from "../../helpers/googleAnalytics.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import LoadMoreButton from "../common/LoadMoreButton.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import Card, { makeCardContentProps } from "../common/Card.js";
import CardLabel from "../common/CardLabel.js";
import Spinner from "../Spinner.js";
import { container } from "../styles/common.css.js";
import { pageTitle, topicDescription } from "./TopicPage.css.js";
import { FlexFeedContainer } from "../common/FeedContainers.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

const first = 10;

const TopicPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { langCode, langCodes } = useContext(Content);

    const { loading, error, data, fetchMore } = useQuery<
        GetTopicContent,
        GetTopicVariables
    >(GET_TOPIC, {
        variables: {
            id,
            langCode,
            first,
            after: undefined,
        },
        notifyOnNetworkStatusChange: true,
    });
    const pagePath = `/topics/${id}`;
    if (error) {
        return <ErrorMsg error={error} />;
    }
    const containerClass = "flex680";
    if (data && data.topic && data.topic.items) {
        const {
            topic: {
                id,
                content: { title, metaTitle, description, metaDescription },
                items: { totalCount, offset, edges },
            },
        } = data;
        const hasMoreNews = totalCount - offset > first;

        const handleLoadMore = () => {
            gaLoadMoreData(langCode, `/topics/${id}`);
            fetchMore({
                variables: {
                    id,
                    langCode: langCode,
                    first,
                    after: edges[edges.length - 1].cursor,
                },
            });
        };
        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={langCodes}
                    path={pagePath}
                    metaTitle={metaTitle}
                    title={title}
                    description={metaDescription || description || ""}
                    largeImagePreview
                />
                <main className={container[containerClass]}>
                    <h1 className={pageTitle}>{title}</h1>
                    {description && (
                        <MarkdownRenderer
                            source={description}
                            className={topicDescription}
                        />
                    )}
                    <FlexFeedContainer>
                        {data.topic.items.edges.map(item => (
                            <Card
                                key={item.node.id}
                                srcSetSizes={{
                                    laptop: 200,
                                    tablet: 188,
                                    phone: 160,
                                }}
                                {...makeCardContentProps(
                                    item.node,
                                    langCode,
                                    pagePath,
                                )}
                            >
                                <CardLabel typename={item.node.__typename} />
                            </Card>
                        ))}
                        {hasMoreNews && (
                            <LoadMoreButton
                                loading={loading}
                                handleLoadMore={handleLoadMore}
                            />
                        )}
                    </FlexFeedContainer>
                </main>
            </>
        );
    }
    if (loading) {
        return <Spinner />;
    }

    return <NotFoundErrorMsg />;
};

export default TopicPage;
