import gql from "graphql-tag";
import type { LangCode } from "../../../_common/types.js";
import { ExperimentsFeatures } from "../../components/GlobalContextsProvider.js";
import { Image } from "./gallery.js";

export interface App {
    __typename: "App";
    id: string;
    title: string;
    icon: AppIcon | null;
    marketUrl: string;
    marketId: string;
    platform: AppPlatform;
}

export type AppPlatform = "ios" | "android" | "huawei";

interface AppIcon {
    sourceUrl: string;
    sizes: string[];
}

export interface ConfigData {
    conversionDomains: string[];
    newsFeedPageItemsCount: number;
    searchFeedPageItemsCount: number;
    recommendedNewsCount: number;
    googleAnalytics4Id?: string;
    experimentsFeatures?: ExperimentsFeatures;
    facebookPixelId?: string;
    twitterUsername?: string;
    adSensePubId?: string;
    vapidPubKey?: string;
    termlyId?: string;
    termlyAutoBlock?: boolean;
}

export interface ImageABTestConfig {
    imageId: string;
    experimentKey: string;
    altImages: (Image | null)[];
}

export interface AppConfig {
    config: Partial<ConfigData> | null;
    promoApps: (App | null)[];
}

export interface GetAppConfigData {
    app: AppConfig | null;
    langs: {
        id: string;
        contents: {
            langCode: LangCode;
            value: string;
        }[];
    };
}

export const GET_APP_CONFIG = gql`
    query getAppConfig {
        app {
            config
            promoApps {
                id
                title
                platform
                icon {
                    sourceUrl
                    sizes
                }
                marketId
                marketUrl
            }
        }
    }
`;
