import React from "react";
import { IImagePreview, Image } from "../../../graphql/queries/gallery.js";
import ImageItemTest from "./ImageItemTest.js";
import ImageItemRenderer from "./ImageItemRenderer.js";

interface Props {
    image: Image | IImagePreview | null;
    srcSetSizes: SrcSetSizes;
    showPlaceholder?: boolean;
    noAspectWrapper?: boolean;
    children?: React.ReactNode;
}

export interface SrcSetSizes {
    phone?: number;
    largePhone?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export const makeBreakpoint = (breakpoint: number, width?: number): string =>
    width ? `(min-width: ${breakpoint}px) ${width}px, ` : "";

const ImageItem: React.FC<Props> = ({
    image,
    srcSetSizes,
    showPlaceholder,
    noAspectWrapper,
    children,
}) => {
    if (!image) return null;

    if (image.abTest) {
        return (
            <ImageItemTest
                variants={[image, ...image.abTest.alternatives]}
                srcSetSizes={srcSetSizes}
                featureKey={image.abTest.experimentKey}
            />
        );
    }

    return (
        <ImageItemRenderer
            image={image}
            showPlaceholder={showPlaceholder}
            noAspectWrapper={noAspectWrapper}
            srcSetSizes={srcSetSizes}
        >
            {children}
        </ImageItemRenderer>
    );
};

export default ImageItem;
