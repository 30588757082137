import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";

import {
    GetContactsData,
    GET_CONTACTS,
} from "../../graphql/queries/contacts.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import { container } from "../styles/common.css.js";
import MetaTags from "../MetaTags.js";
import Spinner from "../Spinner.js";
import Icon from "../svg/Icon.js";
import ContactsForm from "./ContactsForm.js";
import {
    contactsContainer,
    locationIcon,
    locationItem,
    locationsContainer,
    locationText,
    pageSubtitle,
    pageTitle,
} from "./Contacts.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

interface Props {
    path: string;
}

const ContactUs: React.FC<Props> = ({ path }) => {
    const { langCode, langCodes } = useContext(Content);

    const { data, loading, error } = useQuery<GetContactsData>(GET_CONTACTS, {
        variables: {
            langCode,
        },
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }
    if (loading) {
        return <Spinner />;
    }

    if (data && data.staticContent) {
        const {
            title,
            metaTitle,
            metaDescription,
            locationTitle,
            _contactsText,
            _addressOne,
            _addressTwo,
        } = data.staticContent.content;
        const locationItems = [_addressOne, _addressTwo];
        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={langCodes}
                    path={path}
                    title={metaTitle}
                    description={metaDescription}
                />
                <main className={container.flex750}>
                    <h1 className={pageTitle}>{title}</h1>
                    <MarkdownRenderer
                        className={contactsContainer}
                        source={_contactsText}
                    />
                    <h2 className={pageSubtitle}>{locationTitle}</h2>
                    <div className={locationsContainer}>
                        {locationItems.map(item => (
                            <div className={locationItem} key={item}>
                                <Icon className={locationIcon} k="location" />

                                <MarkdownRenderer
                                    source={item}
                                    className={locationText}
                                />
                            </div>
                        ))}
                    </div>

                    <ContactsForm
                        contactsContent={data.staticContent.content}
                    />
                </main>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default ContactUs;
