import React, { useContext } from "react";
import { QuizForFeed } from "../../../graphql/queries/games.js";
import { Content } from "../../layout/CommonContentContextProvider.js";
import { gaRecommendedClick } from "../../../helpers/googleAnalytics.js";
import SummaryBadge from "./SummaryBadge.js";
import Card from "../../common/Card.js";
import { FeedContainerGrid } from "../../common/FeedContainers.js";
import { title } from "./FeaturedQuizList.css.js";

interface Props {
    quizId: string;
    featuredGames: QuizForFeed[];
}
const FeaturedQuizList: React.FC<Props> = ({ quizId, featuredGames }) => {
    const {
        langCode,
        quizPage: { featuredQuizzesTitle },
    } = useContext(Content);
    return (
        <aside>
            <h2 className={title}>{featuredQuizzesTitle}</h2>
            <FeedContainerGrid>
                {featuredGames.map(
                    ({
                        id,
                        date,
                        content: { title, metaDescription, mediaItem },
                        questionsNumber,
                        avgScore,
                        usersCount,
                    }) => (
                        <Card
                            key={id}
                            title={title}
                            date={date}
                            mediaItem={mediaItem}
                            srcSetSizes={{
                                tablet: 102,
                                largePhone: 200,
                            }}
                            link={`/${langCode}/quiz/${id}`}
                            onClickHandler={() =>
                                gaRecommendedClick(
                                    "quiz",
                                    `/quiz/${id}`,
                                    langCode,
                                    `/quiz/${quizId}`,
                                )
                            }
                            description={metaDescription}
                        >
                            <SummaryBadge
                                questionsNumber={questionsNumber}
                                avgScore={avgScore}
                                usersCount={usersCount}
                            />
                        </Card>
                    ),
                )}
            </FeedContainerGrid>
        </aside>
    );
};

export default FeaturedQuizList;
