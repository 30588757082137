import React, { useContext } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { useParams } from "react-router";

import {
    GET_TUTORIAL,
    GetTutorialData,
    GetTutorialVariables,
} from "../../../graphql/queries/tutorials.js";
import Spinner from "../../Spinner.js";
import ErrorMsg from "../../common/errors/ErrorMsg.js";
import TutorialPage from "./TutorialPage.js";
import { Content } from "../../layout/CommonContentContextProvider.js";
import NotFoundErrorMsg from "../../common/errors/NotFoundErrorMsg.js";

const TutorialLoader: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { langCode } = useContext(Content);
    const { loading, error, data } = useQuery<
        GetTutorialData,
        GetTutorialVariables
    >(GET_TUTORIAL, {
        variables: {
            langCode,
            id: id!,
        },
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.tutorial) {
        const { tutorial } = data;

        // const { promoApps } = news;

        // toggleApp(promoApps);
        // const appBanners = promoApps.reduce<AppBannerContent[]>(
        //     (map, { title, icon, marketUrl, platform }) => {
        //         const index = map.findIndex(item => item.title === title);

        //         if (index !== -1) {
        //             map[index].storeBadges.push(
        //                 makeStoreBadge(
        //                     lang,
        //                     marketUrl,
        //                     platform,
        //                     "news",
        //                     news.id,
        //                 ),
        //             );
        //         } else {
        //             const storeBadges = [
        //                 makeStoreBadge(
        //                     lang,
        //                     marketUrl,
        //                     platform,
        //                     "news",
        //                     news.id,
        //                 ),
        //             ];
        //             const appBanner: AppBannerContent = {
        //                 title,
        //                 iconUrl: icon
        //                     ? makeIconUrl(icon.sourceUrl, icon.sizes)
        //                     : undefined,
        //                 // iconAlt: `${title} ${content[lang].common.dict.logo}`,
        //                 storeBadges,
        //             };
        //             map.push(appBanner);
        //         }

        //         return map;
        //     },
        //     [],
        // );

        // const iosApp = promoApps.find(app => app.platform === "ios");

        // const openGraphImageSize = getOpenGraphImageSize(
        //     news.content.mediaItem.thumbnails,
        // );

        return (
            <TutorialPage
                tutorial={tutorial}
                // appBanners={appBanners}
            />
        );
    }

    return <NotFoundErrorMsg />;
};

export default TutorialLoader;
