import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TopicPreview } from "../../graphql/queries/topics.js";
import { gaTopicClick } from "../../helpers/googleAnalytics.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import { tag, topicTagsContainer } from "./TopicTags.css.js";

interface Props {
    topics: (TopicPreview | null)[];
    place: string;
    cpath: string;
    className?: string;
}
const TopicTags: React.FC<Props> = ({ topics, place, cpath, className }) => {
    const _topics = topics.filter((t): t is TopicPreview => !!t);
    if (_topics.length == 0) return null;
    const {
        langCode,
        common: { relatedTopics },
    } = useContext(Content);

    const classNames = [topicTagsContainer];
    if (className) {
        classNames.push(className);
    }
    return (
        <div className={classNames.join(" ")} data-nosnippet>
            <span>{`${relatedTopics}:`}</span>
            {_topics.map(item => (
                <Link
                    className={tag}
                    key={item.id}
                    to={`/${langCode}/topics/${item.id}`}
                    onClick={() =>
                        gaTopicClick(item.id, place, cpath, langCode)
                    }
                >
                    {item.content.title}
                </Link>
            ))}
        </div>
    );
};

export default TopicTags;
