import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";

import {
    AboutPageContent,
    GetAboutContentData,
    GET_ABOUT_CONTENT,
} from "../../graphql/queries/about.js";
import { Content } from "../layout/CommonContentContextProvider.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import Spinner from "../Spinner.js";
import { DOMAIN_URL } from "../../config/env.js";
import { defaultLinkRel } from "../../config/content.js";
import { container, partnerLogo } from "../styles/common.css.js";
import {
    awardLogo,
    awardsBox,
    logoLink,
    pageTitle,
    partnersBox,
    secondTitle,
    textStyle,
} from "./About.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

interface Props {
    path: string;
}
const About: React.FC<Props> = ({ path }) => {
    const { langCode, langCodes } = useContext(Content);

    const { loading, error, data } = useQuery<
        GetAboutContentData<AboutPageContent>
    >(GET_ABOUT_CONTENT, { variables: { langCode } });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.staticContent) {
        const {
            content: {
                metaTitle,
                metaDescription,
                title,
                _text,
                partnersTitle,
                awardsTitle,
                keywords,
            },
        } = data.staticContent;
        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={langCodes}
                    path={path}
                    title={metaTitle}
                    description={metaDescription}
                    keywords={keywords}
                />
                <main className={container.flex750}>
                    <h1 className={pageTitle}>{title}</h1>
                    <MarkdownRenderer className={textStyle} source={_text} />
                    {data.product.content.awards ? (
                        <>
                            <h2 className={secondTitle}>{awardsTitle}</h2>
                            <div className={awardsBox}>
                                {data.product.content.awards.map(
                                    ({ url, imgUrl, alt, rel }, index) =>
                                        url ? (
                                            <a
                                                className={logoLink}
                                                key={`${alt}${index}`}
                                                href={url}
                                                rel={rel || defaultLinkRel}
                                            >
                                                <img
                                                    className={awardLogo}
                                                    src={`${DOMAIN_URL}${imgUrl}`}
                                                    alt={alt}
                                                />
                                            </a>
                                        ) : (
                                            <div
                                                className={logoLink}
                                                key={`${alt}${index}`}
                                            >
                                                <img
                                                    className={awardLogo}
                                                    src={`${DOMAIN_URL}${imgUrl}`}
                                                    alt={alt}
                                                />
                                            </div>
                                        ),
                                )}
                            </div>
                        </>
                    ) : null}
                    <h2 className={secondTitle}>{partnersTitle}</h2>
                    <div className={partnersBox}>
                        {data.partners?.map(
                            ({ url, imageUrl, content: { name }, rel }) => (
                                <a
                                    key={url}
                                    href={url}
                                    rel={rel || defaultLinkRel}
                                >
                                    <img
                                        className={partnerLogo}
                                        src={imageUrl}
                                        alt={name}
                                    />
                                </a>
                            ),
                        )}
                    </div>
                </main>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default About;
