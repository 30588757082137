import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { isDev } from "../../config/env.js";
import { ConfigContext } from "../AppConfigProvider.js";

const HtmlAdSense: React.FC = () => {
    const { adSensePubId } = useContext(ConfigContext);
    if (!adSensePubId) return null;
    if (isDev) return null;
    return (
        <Helmet>
            <script
                data-ad-client={adSensePubId}
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            />
        </Helmet>
    );
};

export default HtmlAdSense;
